import React from "react";

export default function FaqGeneral() {
  return (
    <div>
      <div className="faq-wrapper pb-100 pt-100 bg-black-new">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-5">
              <h2 className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1">
                General
              </h2>
              <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h4" />
            </div>
            <div className="col-12">
              <div
                className="accordion accordion-flush rounded-10 ovh dark-card"
                id="accordionFlushExample1"
              >
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        1. What is Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax is a decentralized trading platform offering tools
                        like automated trading bots, staking, yield farming, and
                        NFTs, designed to streamline and optimize DeFi
                        strategies for its users.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        2. How can I start using Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        To start using Onmax, you need to create an account,
                        connect your crypto wallet, and explore its features
                        like bot trading, staking, or investing in NFTs based on
                        your preferred strategy.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        3. Is Onmax suitable for beginners?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, Onmax is beginner-friendly, providing automated
                        tools like trading bots and copy trading options to help
                        new users navigate the DeFi space easily.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingFour">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      aria-expanded="false"
                      aria-controls="flush-collapseFour"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        4 What types of assets can I trade on Onmax?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax supports a wide range of cryptocurrencies,
                        enabling users to trade assets on the spot market, stake
                        tokens, and participate in yield farming.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-headingFive">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border bg-transparent collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      aria-expanded="false"
                      aria-controls="flush-collapseFive"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        5. Does Onmax charge any fees?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, Onmax charges transaction fees for certain
                        activities like trading and using bots, though the exact
                        fee structure depends on the specific feature or service
                        being used.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
