import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import axios from "axios";
import Pagination from "../Coman/Pagination";
import { ProjectContext } from "../../ProjectContext";

export default function Team() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [level, setlevel] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        level: level,
        pageSize: pageSize
      })
      .then((res) => {

        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getData()
  }, [level])

  return (
    <>
      <Sidebar />
      <div className="dash-main-content vh-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="card bg-transparent border-0 pt-4 pb-3 px-0">
              <div className="card-body p-0 border-0 shadown-none d-flex justify-content-between flex-row mb-2">
                <h5 className="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                  Team
                </h5>
                <div className="d-flex align-content-center">
                  <form
                    action="#"
                    className="float-left ms-auto d-flex align-items-center border border-2 px-4 rounded-10"
                  >
                    <input
                      type="text"
                      placeholder="Start typing to search.."
                      className="form-control rajdhani-900 border-0 lh-32 pt-2 pb-2 ps-2 pr-3 font-xss text-gray-700 font-xl bg-transparent rounded-10"
                      value={level} onChange={(e) => setlevel(e.target.value)} 
                    />
                  </form>
                </div>
              </div>
              <div className="card-body p-0 mt-3">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            #
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Joining Date
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Address
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Referral
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Team
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Direct Team
                          </span>
                        </th>

                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Rank
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Staking
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Team Business
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Status
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ?
                        <tr className="text-center">
                          <td className="text-center" colSpan={10}>
                            Data is loading
                          </td>
                        </tr> : ""}
                      {!isLoading ? (
                        !data.length ? (
                          <tr className="text-center">
                            <td className="text-center" colSpan={10}>
                              <span className="w-100">No data found</span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {data.map((ele, i) => {
                        return (
                          <tr>
                            <th scope="row"></th>
                            <td>
                              <div className="d-flex flex-row gap-2">{ele.id}</div>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{ele.createdAt}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">
                                <span onClick={() =>
                                  copyaddress(ele.address)
                                }>
                                  {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                                </span></h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">
                                <span onClick={() =>
                                  copyaddress(ele.ref_address)
                                }>
                                  {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                                </span>
                              </h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{ele.team}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{ele.ref_team}</h6>
                            </td>

                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                {ele.rank}
                              </h6>
                            </td>
                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                $ {ele.t_staking}
                              </h6>
                            </td>
                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                $ {ele.team_business}
                              </h6>
                            </td>
                            <td>{ele.status ?
                              <h6 className="text-success rajdhani-600 m-0">
                                Active
                              </h6> :
                              <h6 className="text-danger rajdhani-600 m-0">
                                Not Active
                              </h6>
                            }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={pages}
            />

          </div>
        </div>
      </div>
    </>
  );
}
