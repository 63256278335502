/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <nav className="mt-4 mb-4">
        <ul className="pagination pagination-lg justify-content-center">
          <li className="page-item">
            <a
              type="button"
              className="page-link rajdhani-700 font-sm"
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1 ? true : false}
            >
              Prev
            </a>
          </li>
          {currentPage - 2 > 0 ? (
            <li className="page-item " >
              <a
                type="button"
                className="page-link rajdhani-700 font-sm "
                onClick={() => setCurrentPage((prev) => prev - 2)}
              ><span className="page-numbers" >
                  {currentPage - 2}
                </span>
              </a>
            </li>
          ) : (
            ""
          )}
          {currentPage - 1 > 0 ? (
            <li className="page-item">
              <a
                type="button"
                className="page-link rajdhani-700 font-sm"
                onClick={() => setCurrentPage((prev) => prev - 1)}
              ><span className="page-numbers" >
                  {currentPage - 1}
                </span>
              </a>
            </li>
          ) : (
            ""
          )}
          <li className="page-item active">
            <a type="button" className="page-link rajdhani-700 font-sm " disabled={true}>
              <span className="page-numbers current" >
                {currentPage}
              </span>
            </a>
          </li>
          {currentPage + 1 <= pages ? (
            <li className="page-item">
              <a
                type="button"
                className="page-link rajdhani-700 font-sm "
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                <span className="page-numbers" >
                  {currentPage + 1}
                </span>
              </a>
            </li>
          ) : (
            ""
          )}
          {currentPage + 2 <= pages ? (
            <li className="page-item">
              <a
                type="button"
                className="page-link rajdhani-700 font-sm "
                onClick={() => setCurrentPage((prev) => prev + 2)}
              >
                <span className="page-numbers" >
                  {currentPage + 2}
                </span>
              </a>
            </li>
          ) : (
            ""
          )}
          <li className="page-item">
            <a
              type="button"
              className="page-link rajdhani-700 font-sm"
              onClick={() => setCurrentPage(pages)}
              disabled={currentPage === pages ? true : false}
            >
              Next
            </a>
          </li>
        </ul>
      </nav>

    </>
  );
};

export default Pagination;
