import Index from "./Componant/Home/Index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Governance from "./Componant/Governance/Governance";
import Farming from "./Componant/Farming/Farming";
import Trading from "./Componant/Trading/Trading";
import NFTs from "./Componant/NFTs/NFTs";
import AboutUs from "./Componant/AboutUs/AboutUs";
import Bots from "./Componant/Bots/Bots";
import Community from "./Componant/Community/Community";
import Faq from "./Componant/Faq/Faq";
import Ecosystem from "./Componant/Ecosystem/Ecosystem";

import Dashboard from "./Componant/Pages/Dashboard/Dashboard";
import Stake from "./Componant/Pages/Stake/Stake";
import Staking from "./Componant/Staking/Staking";
import Locker from "./Componant/Pages/Locker/Locker";
import StakingReward from "./Componant/Pages/StakingReward";
import ColdReward from "./Componant/Pages/ColdReward";
import RankReward from "./Componant/Pages/RankReward";
import Ledger from "./Componant/Pages/Ledger";
import Unstake from "./Componant/Pages/Unstake";
import PrivacyPolicy from "./Componant/PrivacyPolicy";
import TermsofUse from "./Componant/TermsofUse";
import Disclaimer from "./Componant/Disclaimer";
import Error from "./Componant/Coman/Error";
import StakingHistory from "./Componant/Pages/StakingHistory";
import Team from "./Componant/Pages/Team";
import DirectReward from "./Componant/Pages/DirectReward";
import CommunityReward from "./Componant/Pages/CommunityReward";
import RoyaltyClubReward from "./Componant/Pages/RoyaltyClubReward";
import Withdrawal from "./Componant/Pages/Withdrawal/Withdrawal";
import RoyaltyFund from "./Componant/Pages/RoyaltyFund/RoyaltyFund";
import Viewer from "./Componant/Pages/Viewer";
import AggregatorReward from "./Componant/Pages/AggregatorReward";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/governance" element={<Governance />} />
        <Route path="/farming" element={<Farming />} />
        <Route path="/trading" element={<Trading />} />
        <Route path="/nfts" element={<NFTs />} />
        <Route path="/bots" element={<Bots />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/community" element={<Community />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsofUse />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="*" element={<Error />} />

        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/stake/:link_ref_address" element={<Stake />} />
        <Route path="/security-voult" element={<Locker />} />
        <Route path="/purchase-history" element={<StakingHistory />} />
        <Route path="/team" element={<Team />} />
        <Route path="/staking-reward" element={<StakingReward />} />
        <Route path="/direct-reward" element={<DirectReward />} />
        <Route path="/community-reward" element={<CommunityReward />} />
        <Route path="/rclub-reward" element={<RoyaltyClubReward />} />
        <Route path="/rank-reward" element={<RankReward />} />
        <Route path="/ledger" element={<Ledger />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/royalty-fund" element={<RoyaltyFund />} />
        <Route path="/ledger" element={<Ledger />} />
        <Route path="/view" element={<Viewer />} />
        <Route path="/aggregator-rank" element={<AggregatorReward />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
