import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Coman/Sidebar";
import { ProjectContext } from "../../../ProjectContext";
import axios from "axios";
import Pagination from "../../Coman/Pagination";

export default function Withdrawal() {
  const {
    toastSuccess,
    toastError,
    tokenrate, formatAddress, stakcontract,
    account,
    dbuser, ethers,
    getuserDB
  } = useContext(ProjectContext)
  const [amt, setamt] = useState(0);
  const [amt2, setamt2] = useState(0);
  const [token, settoken] = useState(0);
  const [token2, settoken2] = useState(0);
  const [tokenRcv, settokenRcv] = useState(0);
  const [token2Rcv, settoken2Rcv] = useState(0);
  const [tokenFee, settokenFee] = useState(0);
  const [token2Fee, settoken2Fee] = useState(0);
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [bnb5, setbnb5] = useState(0);
  const [bnb5mining, setbnb5mining] = useState(0);
  const [hash, sethash] = useState("");
  const [trdone, settrdone] = useState(false);
  const [trdonemining, settrdonemining] = useState(false);

  const [bnb5ether, setbnb5ether] = useState('0');
  const [bnb5miningether, setbnb5miningether] = useState('0');

  const withdraw = async (type) => {
    if (!account) {
      toastError("Connect first");
      return;
    }
    if (!dbuser) {
      return;
    }

    if (type === 1) {
      if (!amt) {
        toastError("Enter amount to withdraw");
        return;
      }
      if (amt > dbuser.staking_balance) {
        toastError("Insufficient Balance");
        return;
      }
      // if (amt < 5) {
      //   toastError("Minimum withdrawal is $5 ");
      //   return;
      // }
    } else {
      if (!amt2) {
        toastError("Enter amount to withdraw");
        return;
      }
      if (amt2 > dbuser.mining_balance) {
        toastError("Insufficient Balance");
        return;
      }
      // if (amt2 < 15) {
      //   toastError("Minimum withdrawal is $15 ");
      //   return;
      // }
    }
    if (!dbuser.status) {
      toastError("Your account is not active");
      return;
    }
    if (dbuser.pen_withdrawal) {
      toastError("One withdrawal is pending");
      return;
    }
    // if(dbuser.isBlocked){
    //   toastError("Your account is blocked");
    //   return;
    // }
    if (dbuser.restake) {
      toastError("Please restake your account");
      return;
    }
    if (type === 1) {
      settrdone(true);
    } else {
      settrdonemining(true)
    }
    try {
      var stk = null
      console.log(type === 1 ? bnb5ether : bnb5miningether);

      stk = await stakcontract.withdrawalrequest(ethers.utils.parseEther(`${type === 1 ? tokenRcv : token2Rcv}`), type === 1 ? "1" : "2", { value: type === 1 ? bnb5ether : bnb5miningether })
      // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

      const receipt = await stk.wait();
      sethash(receipt.transactionHash);

      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "withdrawal",
          submethod: "insert",
          key: process.env.REACT_APP_KEY,
          address: account,
          hash: receipt.transactionHash,
          amount: type === 1 ? amt : amt2,
          token: type === 1 ? token : token2,
          type: type, //1.cold , 0.mining
          rate: tokenrate,
          fee: type === 1 ? tokenFee : token2Fee,
          feebnb: type === 1 ? bnb5ether : bnb5miningether,
          t_rcv: type === 1 ? tokenRcv : token2Rcv,
        })
        .then((res) => {
          // var dedata = decryptData(res.data);
          getDataPending();
          getuserDB();

          if (res.data.error) {
            toastError(res.data.error);
            return "";
          }
          setamt(0)
          setamt2(0)

          toastSuccess(res.data.data);

        });
      if (type === 1) {
        settrdone(false);
      } else {
        settrdonemining(false)
      }
    } catch (error) {
      console.log("Error", error);
      toastError("Transaction declined");
      if (type === 1) {
        settrdone(false);
      } else {
        settrdonemining(false)
      }
      return;
    }
  };
  const getDataPending = async () => {
    if (!account) {
      return;
    }
    
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };
  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        setisLoading(false);

        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  const setBnbvalues = async () => {
    if (amt) {
      var cold = await stakcontract.convertValueFromPercentage(ethers.utils.parseEther(`${amt}`), "5");
      setbnb5(Number(ethers.utils.formatUnits(cold, 18)).toFixed(6))
      setbnb5ether(cold)
    }
    if (amt2) {
      var mining = await stakcontract.convertValueFromPercentage(ethers.utils.parseEther(`${amt2}`), "5");
      setbnb5mining(Number(ethers.utils.formatUnits(mining, 18)).toFixed(6))
      setbnb5miningether(mining)
    }
  }
  useEffect(() => {
    getDataPending();
  }, [account]);
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);

  useEffect(() => {
    var tmt = amt / tokenrate;
    // var tmtfee = (tmt * 10) / 100;
    var tmtfee =0
    settoken(tmt);
    settokenFee(tmtfee);
    settokenRcv(tmt - tmtfee);
  }, [amt, tokenrate]);

  useEffect(() => {
    var tmt = amt2 / tokenrate;
    // var tmtfee = (tmt * 10) / 100;
    var tmtfee = 0
    settoken2(tmt);
    settoken2Fee(tmtfee);
    settoken2Rcv(tmt - tmtfee);
  }, [amt2, tokenrate]);
  useEffect(() => {
    setBnbvalues()
  }, [stakcontract, amt, amt2]);


  return (
    <>
      <Sidebar />
      <div className="dash-main-content">
        <div className="middle-sidebar-bottom">
          {/* middle wrap */}
          <div className="middle-sidebar-left vh-100">
            <div className="row ">
              <h5 class="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                Withdrawal
              </h5>
              <div className="d-flex align-items-center justify-content-center pt-5"></div>
            </div>
            <div className="row justify-content-around">
              <div className="col-lg-6">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 bg-white w-100 light-bg  ">
                  <div className="d-flex text-start flex-column mt-5">
                    <div className="d-flex justify-content-between">
                      <h2 className="rajdhani-700 mt-0 mb-0 text-gray-700 ls-0 lh-1 h4 dark-text">
                        Staking Pocket
                      </h2>
                      <h2 className="rajdhani-700 mt-0 mb-0 text-gray-700 ls-0 lh-1 h4 dark-text">
                        $ {dbuser ? dbuser.staking_balance : 0}
                      </h2>
                    </div>

                    <form className="form-style-floating style2 text-center mt-4">
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control rounded-10 font-xl h2 text-gray-800 rajdhani-600 dark-text light-bg "
                          id="floatingPass"
                          onChange={(e) =>
                            setamt(e.target.value)
                          }
                          placeholder="Enter Amount"
                        />
                        <label htmlFor="floatingPass" className="light-bg dark-text"
                        >Enter your Amount</label>
                      </div>
                      <div>
                        <p className="text-dark rajdhani-600 font-md mb-0 dark-text">
                          Total token: <span>{token}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p>
                        {/* <p className="text-dark rajdhani-600 font-md mb-0 dark-text">
                          Fee: <span>{tokenFee}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p> */}
                        <p className="text-dark rajdhani-600 font-md dark-text">
                          Total Recieve: <span>{tokenRcv}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p>
                        <p className="text-dark rajdhani-600 font-md dark-text">
                          Fee BNB: <span>{bnb5} BNB</span>
                        </p>
                      </div>
                      {trdone ? (
                        <div className="text-center">
                          <img
                            src="/assets/images/loader.gif"
                            alt="loading"
                            style={{ width: '100px' }}
                          />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg bg-current text-gray-900 rajdhani-600 text-uppercase btn-block mb-0 rounded-10 "
                          onClick={() => withdraw(1)}
                        >
                          Withdraw now
                        </button>
                      )}
                    </form>
                  </div>
                  <div className="mt-2 pt-2 text-center w-100"></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 bg-white w-100 light-bg  ">
                  <div className="d-flex text-start flex-column mt-5">
                    <div className="d-flex justify-content-between">
                      <h2 className="rajdhani-700 mt-0 mb-0 text-gray-700 ls-0 lh-1 h4 dark-text">
                        Mining Pocket
                      </h2>
                      <h2 className="rajdhani-700 mt-0 mb-0 text-gray-700 ls-0 lh-1 h4 dark-text">
                        $ {dbuser ? dbuser.mining_balance : 0}
                      </h2>
                    </div>

                    <form className="form-style-floating style2 text-center mt-4">
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control rounded-10 font-xl h2 text-gray-800 rajdhani-600 dark-text light-bg "
                          id="amt2"
                          placeholder="Enter Amount"
                          onChange={(e) =>
                            setamt2(e.target.value)
                          }
                        />
                        <label htmlFor="amt2" className="light-bg dark-text"
                        >Enter your Amount</label>
                      </div>
                      <div>
                        <p className="text-dark rajdhani-600 font-md mb-0 dark-text">
                          Total token: <span>{token2}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p>
                        {/* <p className="text-dark rajdhani-600 font-md mb-0 dark-text">
                          Fee: <span>{token2Fee}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p> */}
                        <p className="text-dark rajdhani-600 font-md dark-text">
                          Total Recieve: <span>{token2Rcv}{" "}
                            {process.env.REACT_APP_TICKER}</span>
                        </p>
                        <p className="text-dark rajdhani-600 font-md dark-text">
                          Fee BNB: <span>{bnb5mining} BNB</span>
                        </p>
                      </div>
                      {trdonemining ? (
                        <div className="text-center">
                          <img
                            src="/assets/images/loader.gif"
                            alt="loading"
                            style={{ width: '100px' }}
                          />
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-lg bg-current text-gray-900 rajdhani-600 text-uppercase btn-block mb-0 rounded-10 "
                          onClick={() => withdraw(2)}
                        >
                          Withdraw now
                        </button>
                      )}
                    </form>
                  </div>
                  <div className="mt-2 pt-2 text-center w-100"></div>
                </div>
              </div>
            </div>
            <div>
              {datap.length ?
                <div className="card-body p-0 pt-3 mt-3">
                  <div className="table-responsive">
                    <h5 class="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">Pending Withdrawal</h5>
                    <table className="table mb-0">
                      <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg box-new-sh">
                        <tr>
                          <th scope="col" />

                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Amount
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Token
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Fee
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Total Recieve
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Rate
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Type
                            </span>
                          </th>
                          <th scope="col">
                            <span className="rajdhani-600 lh-24 font-xs m-0">
                              Datetime
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {datap.map((e, i) => {
                          return (
                            <tr>
                              <th scope="row"></th>

                              <td>
                                <h6 className="rajdhani-600 mb-0"> ${e.amount}</h6>
                              </td>
                              <td>
                                <h6 className="rajdhani-600 mb-0">{e.token}{" "}
                                  {process.env.REACT_APP_TICKER}</h6>
                              </td>
                              <td>
                                <h6 className="rajdhani-600 mb-0">{e.fee} {process.env.REACT_APP_TICKER}</h6>
                              </td>
                              <td>
                                <h6 className="rajdhani-600 mb-0">{e.t_rcv} {process.env.REACT_APP_TICKER}</h6>
                              </td>
                              <td>
                                <h6 className="rajdhani-600 mb-0"> ${e.rate}</h6>
                              </td>
                              <td>
                                <h6 className=" rajdhani-600 m-0">
                                  {e.type === 1
                                    ? "Staking Withdrawal"
                                    : "Mining Withdrawal"}
                                </h6>
                              </td>
                              <td>
                                <h6 className=" rajdhani-600 m-0">
                                  {e.datetime}
                                </h6>
                              </td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div> : ''}
              <div className="card-body p-0 pt-3 mt-3">
                <div className="table-responsive">
                  <h5 class="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">Withdrawal List</h5>
                  <table className="table mb-0">
                    <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg box-new-sh">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            #
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Amount
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Token
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Fee
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Recieve
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Rate
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Type
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Datetime
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ?
                        <tr className="text-center box-new-sh">
                          <td className="text-center" colSpan={10}>
                            Data is loading
                          </td>
                        </tr> : ""} <br />
                      {!isLoading ? (
                        !data.length ? (
                          <tr className="text-center">
                            <td className="text-center" colSpan={10}>
                              <span className="w-100">No data found</span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {data.map((e, i) => {
                        return (
                          <tr>
                            <th scope="row"></th>
                            <td>
                              <div className="d-flex flex-row gap-2">{e.id}</div>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0"> ${e.amount}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{e.token}{" "}
                                {process.env.REACT_APP_TICKER}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{e.fee} {process.env.REACT_APP_TICKER}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{e.t_rcv} {process.env.REACT_APP_TICKER}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0"> ${e.rate}</h6>
                            </td>
                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                {e.type === 1
                                  ? "Staking Withdrawal"
                                  : "Mining Withdrawal"}
                              </h6>
                            </td>
                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                {e.datetime}
                              </h6>
                            </td>
                            {/* <td>{ele.status === 1 ?
                              <h6 className="text-success rajdhani-600 m-0">
                                Running
                              </h6> :
                              <h6 className="text-danger rajdhani-600 m-0">
                                Over
                              </h6>
                            }
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
