import React from "react";

export default function BotsFaq() {
  return (
    <div>
      <div className="faq-wrapper pb-100 bg-black-new">
        <div className="container">
          <div className="row">
            <div className="col-12 page-nav d-flex mb-5">
              <h2 className="text-dark-900 dark-text lh-2 m-0 rajdhani-600 h1">
              Bots
              </h2>
              <i className="bi-arrow-up-right text-dark-900 dark-text ms-auto h4" />
            </div>
            <div className="col-12">
              <div
                className="accordion accordion-flush rounded-10 ovh dark-card"
                id="accordionFlushExample1"
              >
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse6"
                      aria-expanded="false"
                      aria-controls="flush-collapse6"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        1. What are Onmax bots?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse6"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading6"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax bots are automated trading tools designed to
                        execute various strategies, such as arbitrage, spot
                        trading, and binary trading, without requiring constant
                        user intervention.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading6">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse7"
                      aria-expanded="false"
                      aria-controls="flush-collapse7"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        2. How do the Onmax bots work?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse7"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading7"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax bots use algorithmic strategies to optimize
                        trading activities in real-time. They analyze market
                        data and execute trades based on preset parameters,
                        allowing users to profit passively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading8">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse8"
                      aria-expanded="false"
                      aria-controls="flush-collapse8"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        3. Can Onmax bots be customized?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse8"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading8"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Yes, users can customize bots according to their trading
                        strategies and risk tolerance, with each bot offering
                        different profit capping and margin levels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading9">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse9"
                      aria-expanded="false"
                      aria-controls="flush-collapse9"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        4. What is the PLUS Subscription with Onmax bots?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse9"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading9"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        PLUS Subscription allows users to access exclusive
                        trading signals, educational materials, and expert
                        strategies, providing additional value beyond automated
                        trading.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item bg-transparent border-light dark-border">
                  <h2 className="accordion-header" id="flush-heading10">
                    <button
                      className="accordion-button ps-0 py-3 border-bottom border-light dark-border collapsed bg-transparent"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapse10"
                      aria-expanded="false"
                      aria-controls="flush-collapse10"
                    >
                      <span className="h5 my-0 lh-36 rajdhani-600 text-gray-900 dark-text">
                        5. How do Onmax bots integrate with NFTs?
                      </span>
                    </button>
                  </h2>
                  <div
                    id="flush-collapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-heading10"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ps-0">
                      <p className="lh-26 h6 text-gray-800 dark-text rajdhani-600">
                        Onmax bots are linked with NFTs, where each bot has
                        unique profit margins and caps, giving users enhanced
                        trading options depending on the NFT they own.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
