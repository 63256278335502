import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";

export default function Sidebar() {
  const { theme, toggleTheme, disconnectNow, dbuser } =
    useContext(ProjectContext);
  const [isSide, setIsSide] = useState(true);
  var navigate = useNavigate();

  const toggleHeader = () => {
    setIsSide(!isSide);
  };
  const currentPath = window.location.pathname;
  const logout = () => {
    disconnectNow();
    navigate("/");
  };
  return (
    <div>
      <nav
        className={
          isSide ? "navigation scroll-bar " : "navigation scroll-bar nav-active"
        }
      >
        <div className="nav-content h-100 d-flex flex-column">
          <div className="nav-top">
            <Link className="navbar-brand  " to="#">
              <img
                src="/assets/images/hero/logo_black.svg"
                className="img-dark-none"
                width={180}
                alt="logo"
              />
              <img
                src="/assets/images/hero/logo_white.svg"
                alt="logo"
                className="img-light-none"
                width={180}
              />
            </Link>

            <a href="#" className="close-nav d-flex d-lg-none me-2">
              <i
                className="feather-x bg-gray-200 mb-4 btn-round-sm font-lg p-2 rounded-pill text-dark ms-auto me-2 mt-4"
                onClick={toggleHeader}
              />
            </a>
          </div>
          {/* <div className="nav-caption inter-500 font-xsss text-gray-500">
            General
          </div> */}
          <ul className="mb-3">
            <li className="logo d-none d-xl-block d-lg-block" />
            <li>
              <Link
                to="/dashboard"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/dashboard.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/dashboard_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/dashboard"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  {" "}
                  Dashboard
                </span>
              </Link>
              {/* <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item">
                  <a href="dashboard-style-1.html">Dashboard Style 1</a>
                </li>
                <li className="menu-item">
                  <a href="dashboard-style-2.html">Dashboard Style 2</a>
                </li>
              </ul> */}
            </li>
            <li>
              <a
                href="#"
                className="nav-content-bttn mega-menu gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/purchase.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/purchase_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === ""
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Purchase
                </span>
              </a>
              <ul className="sub-menu" style={{ display: "none" }}>
                <li className="menu-item ">
                  <Link
                    to="/stake"
                    className={`${
                      currentPath === "/stake"
                        ? " new-pink1  "
                        : " dark-text text-gray-900  "
                    }`}
                  >
                    Stake/Bot
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    to="/purchase-history"
                    className={`${
                      currentPath === "/purchase-history"
                        ? " new-pink1  "
                        : " dark-text text-gray-900  "
                    }`}
                  >
                    Purchase History
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/team" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/team.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/team_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/team"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Team
                </span>
              </Link>
            </li>
            <li>
              <Link to="/aggregator-rank" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/team.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/team_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/aggregator-rank"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Aggregator Rank
                </span>
              </Link>
            </li>

            <li>
              <Link
                to="/staking-reward"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/staking_reward_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/icon/staking_reward.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/staking-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Staking Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link
                to="/direct-reward"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/direct_reward_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/icon/direct_reward.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/direct-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Direct Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link
                to="/community-reward"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/community_reward_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/icon/community_reward.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/community-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Community Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link
                to="/rclub-reward"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/royalty_club_reward_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/icon/royalty_club_reward.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />{" "}
                <span
                  className={`${
                    currentPath === "/rclub-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Royalty Club Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link
                to="/royalty-fund"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/royalty_fund.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/royalty_fund_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/royalty-fund"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Royalty Fund
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link
                to="/rank-reward"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/rank_reward_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/rank_reward.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/rank-reward"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Rank Reward
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/security-voult" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/security_voult.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/security_voult_white.svg"

                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/security-voult"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Security Voult
                </span>
              </Link>
            </li>

            <li className="flex-lg-brackets">
              <Link
                to="/withdrawal"
                className="nav-content-bttn gap-2 box-new-sh"
              >
                <img
                  src="/assets/images/dash/withdrawal_black.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/icon/total_withdrawal.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/withdrawal"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Withdrawal
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/ledger" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/ledger.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/ledger_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/ledger"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Ledger{" "}
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets">
              <Link to="/unstake" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/unstake.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/unstake_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === "/unstake"
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Unstake
                </span>
              </Link>
            </li>

            <li className="flex-lg-brackets">
              <Link to="#" className="nav-content-bttn gap-2 box-new-sh">
                <img
                  src="/assets/images/dash/presentation.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/presentation_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === ""
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Presentation
                </span>
              </Link>
            </li>
            <li className="flex-lg-brackets box-new-sh">
              <a href="#s" className="nav-content-bttn gap-2">
                <img
                  src="/assets/images/dash/help.svg"
                  className="img-dark-none"
                  height={20}
                  alt=""
                />
                <img
                  src="/assets/images/dash/help_white.svg"
                  className="img-light-none"
                  height={20}
                  alt=""
                />
                <span
                  className={`${
                    currentPath === ""
                      ? " new-pink1  "
                      : " dark-text text-gray-900  "
                  }`}
                >
                  Help
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="dash-main-content">
        <div className="middle-sidebar-header d-flex justify-content-end">
          <button className="header-menu" onClick={toggleHeader} />
          <h4 className="rajdhani-600 ls-0 m-0 d-none d-xxl-block dark-text"></h4>
          <ul className="d-flex right-menu-icon align-items-center">
            <li>
              <a
                href="#"
                className="btn btn-md text-gray-900 rajdhani-600 rounded-10 bg-current px-2 px-md-4 h5 rajdhani-700 py-1 font-sm box-new-sh"
              >
                {" "}
                $ {dbuser ? dbuser.balance : 0}
              </a>
            </li>
            <li>
              <a
                href="#"
                onClick={() => logout()}
                className="btn btn-md text-gray-900 rajdhani-600 rounded-10 bg-current px-2 px-md-4 h5 rajdhani-700 py-1 font-sm box-new-sh"
              >
                {/* <i class="bi bi-box-arrow-right m-0"></i> */}
                Logout
              </a>
            </li>

            <a
              className=" menu-btn btn-toggle-dark btn btn-icon btn-round border border-2 rounded-pill ms-2 dark-text mb-2"
              checked={theme === "light-mode"}
              id="themeToggle"
              onClick={() => toggleTheme()}
            >
              <i className="bi-brightness-low font-xl m-0" />
            </a>
            {/* <li>
             <a
                href="#"
                className="btn btn-md text-gray-900 rajdhani-600 rounded-10 bg-current px-4 h5 rajdhani-700 py-1 font-sm"
              >
                {" "}
                $ 0 OMP
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}
