import React from "react";
import Sidebar from "../../Coman/Sidebar";
import DashboardHero from "./DashboardHero";

export default function Dashboard() {
  return (
    <div>
      <Sidebar />
      <DashboardHero />
    </div>
  );
}
