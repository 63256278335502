import React from "react";

export default function OnmaxNft() {
  return (
    <div>
      <div className="feature-wrap pb-100 pt-50 bg-black-new">
        <div className="container py-lg-4">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className=" text-center  text-gray-900 dark-text">
                As Onmax continues to evolve, these NFT collections will play an
                increasingly important role in shaping our ecosystem!
              </h5>
         <div className="card rounded-25 mt-3">
         <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 rounded-25"
                data-setup='{"autoplay":"any"}'
              >
                <source
                  src="assets/images/nft/onmax_nft_video1.mp4"
                  style={{borderRadius:"35px"}}
                  type="video/mp4"
                />
              </video>
         </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
