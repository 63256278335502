import React from "react";

export default function LokerBox() {
  return (
    <div>
      <div className="dash-main-content ">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left vh-100">
            <div class="card-body p-0 border-0 shadown-none d-flex flex-row mb-2">
              <h5 class="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                Security Voult
              </h5>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3 box-new-sh">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2 dark-text">
                      TVL
                    </h6>
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3 box-new-sh">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2 dark-text">
                      Cooling Period
                    </h6>
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3 box-new-sh">
                  <div className="d-flex mb-0 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 mb-0 font-md pb-2 mt-2 dark-text">
                      Every Month (%)
                    </h6>
                  </div>
                  <div className="d-flex posr">
                    <canvas
                      id="doughnutChart"
                      className="doughnutChart graph p-2"
                    />
                    <h2 className="rajdhani-700 text-gray-900 h1 lh-1 dark-text p-0 position-absolute top-50 start-50 translate-middle ">
                      86%{" "}
                    </h2>
                  </div>
                </div>
              </div>

              {/* table */}
            </div>
            <div className="card-body p-0 mt-5">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg box-new-sh">
                    <tr>
                      <th scope="col" />
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Projects
                        </span>
                      </th>
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Volumn
                        </span>
                      </th>
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Market
                        </span>
                      </th>
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Price
                        </span>
                      </th>
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Owner
                        </span>
                      </th>
                      <th scope="col">
                        <span className="rajdhani-600 lh-24 font-xs m-0">
                          Assets
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {" "}
                    <br />
                    <tr className="box-new-sh hover-table">
                      <th scope="row"></th>
                      <td>
                        <div className="d-flex flex-row gap-2">1</div>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">4,881.65 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">148,577.35 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">24.49 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">4,322</h6>
                      </td>
                      <td>
                        <h6 className="text-success rajdhani-600 m-0">
                          49.56 %
                        </h6>
                      </td>
                    </tr>{" "}
                    <br />
                    <tr>
                      <th scope="row">6</th>
                      <td>
                        <div className="d-flex flex-row gap-2">
                          <figure className="avater mb-0">
                            <img
                              src="assets/images/n-2.png"
                              alt="user"
                              className="w-40"
                            />
                          </figure>
                          <h6 className="rajdhani-600 py-1 lh-28 m-0">
                            Ape Kennel Club
                          </h6>
                        </div>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">4,445.65 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">148,577.35 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">4.78 ETH</h6>
                      </td>
                      <td>
                        <h6 className="rajdhani-600 mb-0">4,322</h6>
                      </td>
                      <td>
                        <h6 className="text-success rajdhani-600 m-0">
                          49.56 %
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
