import React from "react";

export default function BCard() {
  return (
    <div>
      <div className="roadmap-wrapper pt-5 pb-5 bg-black-new">
        <div className="container-large py-lg-5">
          <div className="row mb-50">
            <div className="col-md-12 d-flex">
              <h2 className="h1 lh-2 mb-0 text-gray-900 rajdhani-600 dark-text">
                Key Features
              </h2>
              {/* <a href="#" className="text-gray-900 dark-text text-uppercase font-xs rajdhani-700 mt-2 pt-1 ms-auto"><u>Explore all</u></a> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg content-1">
                  <img
                    src="assets/images/bots/automated_trade_execution.png"
                    alt="icon"
                    className="w-55 mb-2 ms-3 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Automated Trade Execution
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Onmax Bots automatically execute trades based on real-time
                    market data, reducing the need for manual monitoring.
                  </p>
                </div>
                <div class="d-flex flex-row justify-content-end py-2 px-4 bg-current">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg content-1">
                  <img
                    src="assets/images/bots/risk_management.png"
                    alt="icon"
                    className="w-55 mb-2 ms-3 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Risk Management
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Users can set stop-loss and take-profit market limit orders
                    to manage risk and protect their investments.
                  </p>
                </div>
                <div class="d-flex flex-row justify-content-end py-2 px-4 bg-current">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg content-1">
                  <img
                    src="assets/images/bots/strategy_customization.png"
                    alt="icon"
                    className="w-55 mb-2 ms-3 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Strategy Customization
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Tailor each bot with personalized trading strategies,
                    including profit margins and trading frequency.
                  </p>
                </div>
                <div class="d-flex flex-row justify-content-end py-2 px-4 bg-current">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg content-1">
                  <img
                    src="assets/images/bots/24-7.png"
                    alt="icon"
                    className="w-55 mb-2 ms-3 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    24/7 Trading
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    The bots operate around the clock, ensuring users never miss
                    an opportunity in the fast-moving crypto markets.
                  </p>
                </div>
                <div class="d-flex flex-row justify-content-end py-2 px-4 bg-current">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg content-1">
                  <img
                    src="assets/images/bots/market_analysis.png"
                    alt="icon"
                    className="w-55 mb-2 ms-3 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Market Analysis
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Onmax Bots continuously analyze market trends and
                    conditions, optimizing trade decisions for maximum
                    profitability.
                  </p>
                </div>
                <div class="d-flex flex-row justify-content-end py-2 px-4 bg-current">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
