import React from "react";

export default function ComCard() {
  return (
    <div>
      <div className="roadmap-wrapper pt-5 pb-5 bg-black-new">
        <div className="container-large py-lg-5">
          <div className="team1">
         <div className="d-flex gap-4 flex-wrap justify-content-center ">
         <div className="team-card1">
              <a href="https://x.com/onmaxprotocol" target="_blank">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/twitter.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">Twitter</h5>
                  {/* <div >Front-end developer</div> */}
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="https://www.facebook.com/people/OnmaxProtocol/61555490522388/" target="_black">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/facebook.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">Facebook</h5>
                  {/* <div >UX/UI designer</div> */}
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="https://t.me/+Q-IBiQwK51JmNDg8" target="_black">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                    <img
                      src="assets/images/com/telegram.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">Telegram</h5>
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="https://www.instagram.com/onmaxprotocol/" target="_black">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/instagram.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">Instagram</h5>
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="https://www.reddit.com/user/onmaxprotocol/" target="_black">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/reddit.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">reddit</h5>
                </div>
              </a>
            </div>
            <div className="team-card1">
              <a href="https://www.youtube.com/@onmaxprotocol" target="_black">
                <div className="team-card-top1">
                  <div className="team-card-image1">
                  <img
                      src="assets/images/com/youtube.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="team-card-bottom1">
                  <h5 className="dark-text">YouTube</h5>
                </div>
              </a>
            </div>
         </div>
          </div>
        </div>
      </div>
    </div>
  );
}
