import React from "react";

export default function BBox() {
  return (
    <div>
      <div className="feature-wrap py-5 pt-50 bg-black-new">
        <div className="container py-lg-4">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h2 className="rajdhani-700 h1 text-center mb-4 text-gray-900 dark-text">
                How Onmax Bots Dominate Automated Trading
              </h2>
              <p className="text-gray-800 px-lg-4 mb-4 text-center rajdhani-600 font-sm mb-3 dark-text">
                Onmax Bots stand out as the best due to their advanced
                automation, allowing users to execute complex trading strategies
                effortlessly with real-time market analysis and optimization.
                Combined with customizable risk management tools and seamless
                integration with DeFi features, these bots provide users with a
                hands-free, highly efficient way to maximize profits across
                multiple markets.
              </p>
            </div>
          </div>
          <div className="feature-wrapper">
            <div className="container ">
            
              <div className="row">
                <div className="col-md-4 mt-3">
                  <div className="icon-div d-flex flex-column text-center posr">
          <div>
          <img src="assets/images/hero/yellow_fox.png" className="mb-2" width={80} height={80} alt="" />
          </div>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Yellow Fox
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                      Yellow Fox is Onmax's entry-level trading bot, making it
                      the perfect starting point for users new to decentralized
                      finance and automated trading. This bot is designed with
                      simplicity in mind, allowing users to get accustomed to
                      the platform without needing extensive technical knowledge
                      or a deep understanding of complex trading strategies.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="icon-div d-flex flex-column text-center posr">
                  <div>
          <img src="assets/images/hero/brown_fox.png" className="mb-2" width={80} height={80} alt="" />
          </div>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Brown Fox
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                      For those who have gained some experience in DeFi trading
                      and are ready to take their strategies to the next level,
                      the Brown Fox trading bot is the ideal choice. Brown Fox
                      builds on the foundation set by Yellow Fox, offering more
                      exclusive trading features that cater to users seeking
                      increased profit potential while maintaining a balanced
                      approach to risk.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 mt-3">
                  <div className="icon-div d-flex flex-column text-center posr">
                  <div>
          <img src="assets/images/hero/black_fox.png" className="mb-2" width={80} height={80} alt="" />
          </div>
                    <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                      Black Fox
                    </h4>
                    <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                      Black Fox trading bot represents the premium option in
                      Onmax’s suite of trading bots, specifically designed for
                      users looking to unlock the highest profit potentials.
                      Tailored for advanced traders and experienced DeFi
                      enthusiasts, Black Fox provides access to cutting-edge
                      trading strategies that focus on maximizing returns from
                      even the most volatile markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
