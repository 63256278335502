import React from "react";
import { Link } from "react-router-dom";

export default function Fooertop() {
  return (
    <div>
 <div className="faq-wrapper pb-100 black-bg pt-100">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="subscribe-banner rounded-10 "  style={{ backgroundImage: "url(assets/images/hero/join_us_&_start_trading_new.jpg",backgroundSize:"cover" }}>
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center">
              <h2 className="h1 text-white rajdhani-600 lh-1 m-0">Join us & start trading!</h2>
              <p className="lh-26 h6 text-gray-300 px-md-5 mt-3 rajdhani-500">Onmax offers exponential opportunities for traders & investors to benefit with prominent DeFi trading tools. 
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 mt-4 text-center">
              <a href="/community" className="btn btn-xl text-gray-900 bg-white rounded-6 px-5 rajdhani-700 font-sm text-uppercase"> Join Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
}
