import React, { useState } from "react";

export default function Discover() {
  const [showtab, setShowTab] = useState(0);

  return (
    <div>
      <div
        className="banner-wrapper posr bg-image-cover dark-bg bg-image-none-dark bg-image-center bg-no-repeat"
        style={{ backgroundImage: "url(assets/images/base.png)" }}
      >
        <div className="banner-pattern" style={{ zIndex: 1 }}>
          <img
            src="assets/images/pattern-2.png"
            alt="pattern"
            className="ani4 pattern1 w-51"
          />
          <img
            src="assets/images/planet-5.webp"
            alt="pattern"
            className="ani2 pattern2 w-110"
          />
          <img
            src="assets/images/pattern-1.png"
            alt="pattern"
            className="ani4 pattern3"
          />
          <img
            src="assets/images/pattern-2.png"
            alt="pattern"
            className="ani2 pattern4"
          />
          <img
            src="assets/images/planet-6.webp"
            alt="pattern"
            className="ani4 pattern5 w-110"
          />
          <img
            src="assets/images/pattern-3.png"
            alt="pattern"
            className="ani3 pattern6"
          />
        </div>
        <div className="container-wide py-lg-5">
          <div className="row justify-content-center pt-5 pb-3">
            <div className="col-xxl-10 col-12 text-center z-index-5 posr">
              <h2 className="text-gray-900 dark-text rajdhani-700 lh-2 display3-size mb-2">
                Discover Our Prominent DeFi Tools
              </h2>
              <p className="rajdhani-600 dark-text font-sm lh-28 text-gray-900 mt-0">
                Onmax offer various trading tools to enhance your experience in
                DeFi trading
              </p>
            </div>
          </div>
          <div className="row justify-content-center pb-5 z-index-5 posr">
            <div className="col-xl-6 col-lg-10">
              <form
                action="#"
                className="bg-white dark-bg3 rounded-pill p-2 form-style-floating d-none d-md-block"
              >
                <div className="row mx-0 justify-content-around">
                  <div className="p-0 col-4 ">
                    <div className="mx-2">
                      <a
                        className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5  dark-text ${
                          showtab === 0 ? "bg-current" : "border"
                        }`}
                        onClick={() => setShowTab(0)}
                      >
                        Trading
                      </a>
                    </div>
                  </div>
                  <div className="p-0 col-4  ">
                    {/* <i className="feather-image font-lg p-3 text-gray-500" /> */}
                    <div className="mx-2">
                      <a
                        className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5 dark-text ${
                          showtab === 1 ? "bg-current" : "border"
                        }`}
                        onClick={() => setShowTab(1)}
                      >
                        Staking
                      </a>
                    </div>
                  </div>
                  <div className="p-0 col-4">
                    <div className="mx-2">
                      <a
                        className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5  dark-text ${
                          showtab === 2 ? "bg-current" : "border"
                        }`}
                        onClick={() => setShowTab(2)}
                      >
                        Farming
                      </a>
                    </div>
                  </div>
                </div>
              </form>
              <form
                action="#"
                className="bg-black-new rounded-15 p-2 form-style-floating d-md-none d-lg-none d-xl-none"
              >
                <div className="row mx-0">
                  <div className="p-0 col-12 d-flex flex-row my-2">
                    <a
                      className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5 dark-text ${
                        showtab === 0 ? "bg-current" : "border"
                      }`}
                      onClick={() => setShowTab(0)}
                    >
                      Trading
                    </a>
                  </div>
                  <div className="p-0 col-12 d-flex flex-row mb-3">
                    <a
                      className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5 dark-text ${
                        showtab === 1 ? "bg-current" : "border"
                      }`}
                      onClick={() => setShowTab(1)}
                    >
                      Staking
                    </a>
                  </div>
                  <div className="p-0 col-12">
                    <a
                      className={`btn btn-block btn-lg rajdhani-600 text-gray-900 text-uppercase border rounded-pill px-5 dark-text ${
                        showtab === 2 ? "bg-current" : "border"
                      }`}
                      onClick={() => setShowTab(2)}
                    >
                      Farming
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={showtab === 0 ? "" : "d-none"}>
            <div className="row justify-content-center pb-3">
              <div className="col-xxl-6 col-xl-7 col-lg-9 text-center z-index-5 posr">
                <h3 className="text-gray-900 dark-text rajdhani-700 lh-2 display1-size mb-2">
                  Onmax Trading Bots
                </h3>
                <p className="rajdhani-600 dark-text font-sm lh-28 text-gray-900 m-0">
                  Choose one of Trading Bots to get started with Onmax!
                </p>
              </div>
            </div>
            <div className="row justify-content-center pb-5 z-index-5 posr">
              <div className="col-xl-11 col-12">
                <div className="row">
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(253 126 20 / 22%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/yellow_fox.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Yellow Fox
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Access Onmax with the YellowFox as an entry level
                        trading bot. <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(165 42 42 / 39%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/brown_fox.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Brown Fox
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Exclusive trading bot BrownFox comes with increased
                        profit capping. <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(0 0 0 / 33%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/black_fox.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Black Fox
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Trade with premium bot BlackFox & unlock highest profit
                        potentials. <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={showtab === 1 ? "" : "d-none"}>
            <div className="row justify-content-center pb-3">
              <div className="col-xxl-6 col-xl-7 col-lg-9 text-center z-index-5 posr">
                <h3 className="text-gray-900 dark-text rajdhani-700 lh-2 display1-size mb-2">
                  Onmax Staking Facility
                </h3>
                <p className="rajdhani-600 dark-text font-sm lh-28 text-gray-900 mt-0">
                  Start staking crypto assets at Onmax!
                </p>
              </div>
            </div>
            <div className="row justify-content-center pb-5 z-index-5 posr">
              <div className="col-xl-11 col-12">
                <div className="row">
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "#ffc0cb85" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/high_staking_yields.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        High Staking Yields
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Offering industry standard staking yields for top crypto
                        assets.
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(253 126 20 / 23%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/referral_rewards.png"
                          height={78}
                          width={78}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Referral Rewards
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Boost your earnings by participating in our referral
                        program.
                        <br />
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(0 0 0 / 33%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/flexible_staking_plans.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Flexible Staking Plans
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Users can choose to stake assets as per their convenient
                        time. <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={showtab === 2 ? "" : "d-none"}>
            <div className="row justify-content-center pb-3">
              <div className="col-xxl-6 col-xl-7 col-lg-9 text-center z-index-5 posr">
                <h3 className="text-gray-900 dark-text rajdhani-700 lh-2 display1-size mb-2">
                  Farm Yields & Liquidity
                </h3>
                <p className="rajdhani-600 dark-text font-sm lh-28 text-gray-900 mt-0">
                  Use your assets for market making, receive rewards!
                </p>
              </div>
            </div>
            <div className="row justify-content-center pb-5 z-index-5 posr">
              <div className="col-xl-11 col-12">
                <div className="row justify-content-center">
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgba(255, 192, 203, 0.52)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/yield_farming.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Yield Farming
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Start earning yields on your crypto holding by staking,
                        lending & market making <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgb(253 126 20 / 23%)" }}
                    >
                      <div>
                        <img
                          src="assets/images/hero/wide_asset_range.png"
                          height={78}
                          width={78}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Wide Asset Range
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Support for wide range of crypto assets including ETH,
                        SOL & others.
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 lg-mb-4">
                    <div
                      className="d-flex p-4 rounded-10 flex-column c-box-sha text-center"
                      style={{ background: "rgba(0, 0, 0, 0.33)"}}
                    >
                      <div>
                        <img
                          src="assets/images/hero/liquidity_mining.png"
                          height={80}
                          width={80}
                          alt=""
                        />
                      </div>
                      <h2 className="rajdhani-600 text-gray-900 h5 my-2 dark-text">
                        Liquidity Mining
                      </h2>
                      <p className="text-gray-800 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 dark-text">
                        Use liquidity to earn additional rewards on trading
                        pairs in live secondary markets.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
