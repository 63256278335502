import React from 'react'

export default function FCreate() {
  return (
    <div>
        <div className="feature-wrapper pt-100 bg-black-new">
  <div className="container pb-lg-5">
    <div className="row justify-content-center">
      <div className="col-lg-8 text-center  gap-2 pb-75 ">
        <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text ">Get started with Yield Farming
        </h2>
        <p className="rajdhani-600 dark-text h6 lh-28 text-gray-900 w-md-75 mt-2 pe-md-5">Follow these simple steps to get started with yield farming on Onmax!
</p>

      </div>
    </div>
    <div className="row">
      <div className="col-lg-4">
        <div className="d-flex flex-row gap-3 get-hov p-2 p-md-4">
          <div className="w-110 d-flex">
          <span class="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto text-white font-lg">
            <img  src="assets/images/farm/connect_wallet.svg" width={40} height={40} alt="" />

            </span>
          </div>
          <div className="d-flex flex-column">
            <h6 className="rajdhani-600 mt-0 mb-1 h5 text-gray-900 dark-text">Connect Wallet</h6>
            <p className="text-gray-600 dark-text rajdhani-600 mt-1 font-sm lh-26 mb-0">Navigate to Farming & connect your wallet. </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="d-flex flex-row gap-3 get-hov p-2 p-md-4">
          <div className="w-110 d-flex">
            <span class="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto text-white font-lg">
            <img  src="assets/images/farm/provide_liquidity.svg" width={40} height={40} alt="" />

            </span>
            {/* <i className="rounded-pill bg-success mx-auto bi-shield-shaded text-white font-lg" /> */}
          </div>
          <div className="d-flex flex-column">
            <h6 className="rajdhani-600 mt-0 mb-1 h5 text-gray-900 dark-text">Provide Liquidity  </h6>
            <p className="text-gray-600 dark-text rajdhani-600 mt-1 font-sm lh-26 mb-0">Add liquidity to your choice of open-pools.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="d-flex flex-row gap-3 get-hov p-2 p-md-4">
          <div className="w-110 d-flex">
          <span class="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto text-white font-lg">
            <img  src="assets/images/farm/harvest_yields.svg" width={40} height={40} alt="" />

            </span>
          </div>
          <div className="d-flex flex-column">
            <h6 className="rajdhani-600 mt-0 mb-1 h5 text-gray-900 dark-text">Harvest Yields</h6>
            <p className="text-gray-600 dark-text rajdhani-600 mt-1 font-sm lh-26 mb-0">See your assets being utilized & harvest earnings.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}
