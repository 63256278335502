import React from "react";

export default function FarmingYield() {
  return (
    <div className="bg-black-new">
      <div className="container-large pb-lg-5 pt-100 ">
        <div className="row justify-content-center">
          <div className="col-lg-9   mb-lg-5 mb-4 ">
            <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text text-center">
            How Onmax Maximizes Yield Farming
            </h2>
            <p className="rajdhani-600 dark-text h6 lh-28 text-gray-900 w-md-75 mt-2 pe-md-5 text-center">
            Onmax’s approach to yield farming is designed to ensure that users get the most out of their investments. The platform does this through several innovative features:
            </p>

          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 md-mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column  new-pink-bg"
              // style={{ background: "#ff44ff2e" }}
            >
             <img  src="assets/images/farm/dynamic_liquidity_pools.svg" width={60} height={60} alt="" />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white">
              Dynamic Liquidity Pools
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 text-white  mb-3">
              Onmax offers dynamic liquidity pools that adjust based on market conditions and user activity. This ensures that users can take advantage of optimal returns without constantly monitoring the platform. 
              </p>
           
      
            </div>
          </div>
          <div className="col-lg-4 md-mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column new-pink-bg"
              // style={{ background: "#ECFFF6" }}
            >
             <img  src="assets/images/farm/yield_optimizers.svg" width={60} height={60} alt="" />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white ">
              Yield Optimizers
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-0 text-white ">
              Onmax employs yield optimizers to automatically allocate users pooled assets into the highest-performing liquidity pools. This allows users to take full advantage of the most profitable opportunities without needing to perform manual transfers.
              </p>
            
            </div>
          </div>
          <div className="col-lg-4 md-mb-4">
            <div
              className="d-flex p-4 rounded-10 flex-column new-pink-bg" 
              // style={{ background: "#FFF5EB" }}
            >
            <img  src="assets/images/farm/risk_mitigation_strategies.svg" width={60} height={60} alt="" />
              <h2 className="rajdhani-600 text-gray-900 h5 my-2 text-white ">
              Risk Mitigation Strategies
              </h2>
              <p className="text-gray-600 rajdhani-600 mt-1 font-sm pe-lg-3 lh-26 mb-3 text-white ">
              Onmax reduces this risk by offering impermanent loss protection mechanisms in certain pools, ensuring users can farm without the fear of losing value due to token price fluctuations.

              </p>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
