import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Error() {
  return (
    <div className=''>
        <Header/>
        <div className="nft-wrapper pb-50 pt-50  bg-black-new vh-100">
  <div className="container pb-5">
    <div className="row justify-content-center">
      <div className="col-lg-6 text-center pt-lg-5 pb-lg-5">
      <h1 className="text-gray-900 display3-size rajdhani-700 lh-1 pt-5 dark-text">404! Not Found</h1>
        <h1 className="text-gray-900 display1-size rajdhani-500 lh-1 dark-text pb-5">Oops! It looks like you're lost.</h1>
      </div>
    </div>
  </div>
        <Footer/>
</div>

    </div>
  )
}
