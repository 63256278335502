import React from "react";

export default function AboutHero() {
  return (
    <div className="bg-black-new">
      <div className="container pt-150 pb-75 ">
        <div className="row">
          <div className="col-12 page-nav justify-content-center d-flex">
            <h2 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 display1-size dark-text">
              About Us
            </h2>
          </div>
          <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-5 pe-lg-5">
            Onmax is an innovative trading platform designed to facilitate
            seamless cryptocurrency trading. Our team is composed of passionate
            blockchain enthusiasts, experienced traders, and technology experts
            dedicated to creating a user-friendly environment that fosters
            growth, education, and collaboration.
          </p>
          <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75  pe-lg-5">
            At Onmax, we strive to empower individuals in the world of
            decentralized finance (DeFi) through innovative trading solutions
            and advanced technologies.
          </p>
        </div>
      </div>
    </div>
  );
}
