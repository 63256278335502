import React from "react";

export default function EcosystemdApps() {
  return (
    <>
      <div className="feature-wrapper black-bg ">
        <div className="container pt-100">
          <div className="text-center">
            <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              Onmax dApps{" "}
            </h2>
            <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-5 pe-lg-5">
              Explore the rich offerings of our ecosystem, designed to enhance
              your DeFi journey and provide utilities that go beyond traditional
              crypto platforms.
            </p>
          </div>
          <div className="row pt-4 align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/eco/onplay.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
               <span className="new-pink1"> OnPlay</span> – A Play-to-Earn Gaming dApp
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                OnPlay offers an immersive play-to-earn experience, allowing
                gamers to earn rewards in crypto as they participate in various
                games within the platform. The decentralized nature of OnPlay
                ensures that players truly own their in-game assets, which can
                be traded or sold in open markets. Whether you're a casual gamer
                or a competitive player, OnPlay gives you the opportunity to
                turn your gaming skills into real-world rewards.
              </h6>{" "}
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Earn while you play :</h6>
                  <p className="dark-text">
                    {" "}
                    Accumulate crypto rewards for achieving in-game milestones
                    and winning tournaments.
                  </p>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">True ownership of assets:</h6>
                  <p className="dark-text">
                    {" "}
                    Secure your game items and NFTs on the blockchain.
                    Interoperability: Use your gaming rewards across other dApps
                    in the Onmax Ecosystem.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row pt-4 align-items-center">
            <div className="col-lg-6 col-md-12 ">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              <span className="new-pink1">AskMe Fox</span>   – The ChatBot for All Your Needs
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                AskMe Fox is a multi-functional chatbot designed to guide you
                through every aspect of the Onmax Ecosystem. Whether you need
                help navigating trading bots, staking, or yield farming, AskMe
                Fox provides instant answers and personalized assistance. It is
                also integrated with Onmax’s DeFi services, allowing users to
                execute quick actions, like managing wallets, retrieving market
                data, and even getting personalized investment tips.
              </h6>{" "}
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">24/7 support :</h6>
                  <p className="dark-text">  AskMe Fox is always ready to assist with your queries.</p>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Instant insights :</h6>
                  <p className="dark-text"> Get real-time market updates and asset suggestions.</p>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Easy platform navigation :</h6>
                  <p className="dark-text">
                    {" "}
                    Quickly execute tasks, access wallets, and more with one
                    simple chat.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-md-12 ms-auto md-mt-4">
              <img
                src="assets/images/eco/askme_fox.png"
                alt="banner"
                className="img-fluid img-dark-none"
              />
                 <img
                src="assets/images/eco/askme_fox_dark.png"
                alt="banner"
                className="img-fluid img-light-none"
              />
            </div>
          </div>
          <div className="row pt-4 align-items-center">
            <div className="col-lg-5 col-md-12">
              <img
                src="assets/images/eco/magic_box.png"
                alt="banner"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
              <span className="new-pink1"> Magic Box </span>    – Decentralized Lottery & Jackpot Platform

              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
              Magic Box is a decentralized lottery and jackpot platform that provides users with fair, transparent, and blockchain-secured chances to win. Unlike traditional lottery systems, Magic Box leverages smart contracts to ensure every entry is transparent and every winner is verifiable on the blockchain. With frequent draws and varying prize tiers, Magic Box offers participants the thrill of potentially winning life-changing prizes in a secure environment.

              </h6>{" "}
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Decentralized and fair :</h6>
                  <p className="dark-text">
                    {" "}
                    Every lottery draw is governed by smart contracts, ensuring transparency.
                  </p>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Multiple prizes :</h6>
                  <p className="dark-text">
                    {" "}
                    Enter for a chance to win a range of prizes, from small daily rewards to large jackpots.
                  </p>
                </li>
              </ul>
              <ul className="d-flex">
                <li className="d-flex  align-content-center">
                  <i className="new-pink1 font-xs bi-check-circle-fill" />
                </li>
                <li className="ms-2">
                  <h6 className="mb-0 dark-text">Seamless integration :</h6>
                  <p className="dark-text">
                    {" "}
                    Use Onmax’s native OM token to buy tickets and claim winnings.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
