import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import axios from "axios";
import Pagination from "../Coman/Pagination";
import { ProjectContext } from "../../ProjectContext";

export default function RoyaltyClubReward() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "rclub",
        submethod: "getbyid",
        address: account,
        // address: "address",
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        console.log("re",res.data);
        
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);



  return (
    <>
      <Sidebar />
      <div className="dash-main-content vh-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="card bg-transparent border-0 pt-4 pb-3 px-0">
              <div className="card-body p-0 border-0 shadown-none d-flex flex-row mb-2">
                <h5 className="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                  Royalty Club Reward
                </h5>
              </div>
              <div className="card-body p-0 mt-3">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="thead-light bg-gray-200 text-gray-900 dark-text-black rounded-6 ovh light-bg box-new-sh ">
                      <tr>
                        <th scope="col" />
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            #
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Rank
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Income
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Staking
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Club Reward
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Users
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Per %
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Datetime
                          </span>
                        </th>
                      </tr>
                    </thead> <br />
                    <tbody>
                    <tr className="box-new-sh hover-table">
                        <th scope="col" />
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            #
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Rank
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Income
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Staking
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Royalty Reward
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Total Users
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Per %
                          </span>
                        </th>
                        <th scope="col">
                          <span className="rajdhani-600 lh-24 font-xs m-0">
                            Datetime
                          </span>
                        </th>
                      </tr>
                      {isLoading ?
                        <tr className="text-center box-new-sh hover-table">
                          <td className="text-center" colSpan={10}>
                            Data is loading
                          </td>
                        </tr> : ""}
                        <br />
                      {!isLoading ? (
                        !data.length ? (
                          <tr className="text-center">
                            <td className="text-center" colSpan={10}>
                              <span className="w-100">No data found</span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {data.map((ele, i) => {
                        return (
                          <tr>
                            <th scope="row"></th>
                            <td>
                              <div className="d-flex flex-row gap-2">{ele.id}</div>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0"> {ele.rank}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">$ {ele.income}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">$ {ele.t_staking}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">$ {ele.club_reward}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0"> {ele.t_users}</h6>
                            </td>
                            <td>
                              <h6 className="rajdhani-600 mb-0">{ele.per} %</h6>
                            </td>
                            <td>
                              <h6 className=" rajdhani-600 m-0">
                                {ele.createdAt}
                              </h6>
                            </td>
                            {/* <td>{ele.status === 1 ?
                              <h6 className="text-success rajdhani-600 m-0">
                                Running
                              </h6> :
                              <h6 className="text-danger rajdhani-600 m-0">
                                Over
                              </h6>
                            }
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={pages}
            />

          </div>
        </div>
      </div>
    </>
  );
}
