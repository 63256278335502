import React from 'react'
import Header from '../Coman/Header'
import CommunityHero from './CommunityHero'
import ComCard from './ComCard'
import Footer from '../Coman/Footer'
import CommunityCont from './CommunityCont'

export default function Community() {
  return (
    <>
        <Header/>
        <CommunityHero/>
        <ComCard/>
        <CommunityCont/>
        <div className='pt-100 bg-black-new'></div>
        <Footer/>
    </>
  )
}
