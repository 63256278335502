import React, { useEffect, useState } from "react";
import axios from "axios";

export default function RoyaltyFundHero() {
  const [data, setdata] = useState([]);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "rfund",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  
  return (
    <div>
      <div className="row mt-3">
        {data.map((ele) => {
          return (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
              <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                <div className="col-3  pe-0">
                  <div className="rounded-pill bg-current btn btn-icon btn-md">
                    <img src="assets/images/dash/ro/gold.svg" height={30} alt="" />
                  </div>
                </div>
                <div className="col-9 ps-0 ms-auto text-center ">
                  <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                    {ele.rank}
                  </h6>
                  <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                    $ {ele.rank_staking}
                  </h2>
                </div>
              </div>
            </div>
          )
        })}

      </div>
    </div>
  );
}
