import React from "react";

export default function GovernanceHero() {
  return (
    <div>
      <div
        className="banner-wrapper style13 bg-image-none-dark py-4 bg-black-new pt-100 bg-image-top bg-no-repeat"
        style={{ backgroundImage: "url(images/bg-30.jpg)" }}
      >
        <div className="container-wide py-xl-5">
          <div className="row justify-content-between align-content-center">
            <div className="col-lg-6 col-md-6 mt-md-4 md-mb-3">
              <h1 className="display5-size lh-1 text-gray-900 dark-text rajdhani-700">
                Help Shaping Future of Onmax
              </h1>
              <p className="rajdhani-600 dark-text h6 lh-28 text-gray-700 w-xl-75 mt-2">
                Make key decisions to shape the future of the Onmax Ecosystem.
              </p>
              <div className="d-flex flex-row gap-3 mt-4 pt-3">
                <a
                  href="#"
                  className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 px-lg-5 font-sm text-uppercase"
                >
                  Join Governance
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ps-md-4 posr mt-md-4 md-mb-3">
              <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-dark-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/gov/hero.mp4" type="video/mp4" />
              </video>
              <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-light-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/gov/dark-hero.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
