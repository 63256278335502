import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../ProjectContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function StakingBox() {
  const {
    account,
    balance,
    toastSuccess,
    library,
    dbuser,
    tokenrate, formatAddress,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)
  const [amount, setamount] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [tandc, settandc] = useState(false);

  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [referral, setreferral] = useState("");
  const { link_ref_address } = useParams();
  var navigate = useNavigate()
  const onDeposit = async () => {
    settrdone(true);
    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount) {
      toastError("Enter Amount");
      settrdone(false);
      return;
    }
    if (!tandc) {
      toastError("Accept terms and conditions");
      settrdone(false);
      return;
  }
    // if (Number(balance) < Number(total_tokens)) {
    //   toastError("Insufficient balance");
    //   return;
    // }
    if (Number(amount) < 50 || Number(amount) > 5000) {
      toastError("Amount should be between $50 and $5000");
      settrdone(false);
      return
    }

    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    var data = await getudata(referral);
    if (data.data.error) {
      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    // if (dbuser !== null && dbuser.last_staking > amount) {
    //     toastError(`Stake ${dbuser.last_staking} or more`);
    //     settrdone(false);
    //     return;
    // }
    let contract = new library.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );

    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = null
        stk = await contract.stake(ethers.utils.parseEther(`${amount}`), referral);
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: amount,
            token: total_tokens,
            token_rate: tokenrate
          })
          .then((res) => {
            console.log("res", res.data);
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        toastSuccess("Transaction done");
        setamount(0)
        getuserDB();
        settrdone(false);
        navigate("/dashboard");

      } catch (error) {
        console.log("Error", error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }

    }
    if (Number(ethers.utils.formatEther(allowance)) < total_tokens) {
      // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [dbuser, account]);
  useEffect(() => {
    settotal_tokens(amount / tokenrate);
  }, [amount, tokenrate]);



  return (
    <>
      <div className="main-wrapper bg-image-cover bg-image-center">
        <div className=" mb-3">
          <div className="row mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-8 col-md-10 col-sm-11 col-12">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 light-bg  w-100">
                  <div className="d-flex text-start flex-column mt-5">
                    <h1 className="rajdhani-700 mt-0 mb-0 text-gray-800 ls-0 lh-1 h2 dark-text">
                      Staking
                    </h1>
                    {/* <h6 className="text-gray-500 lh-20 mt-2 font-xs">
                      Enter your information to get started.
                    </h6> */}
                    <form className="form-style-floating style2 text-center mt-4 ">
                      <div className="form-floating mb-3">

                        {dbuser !== null ? (
                          dbuser.ref_address !== null ? (
                            <input
                              type="text"
                              id="referid"
                              className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text"
                              value={referral}
                            />
                          ) : (
                            <input
                              type="text"
                              id="referid"
                              placeholder="Referral Address"
                              value={referral}
                              className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text"
                              onChange={(e) => { setreferral(e.target.value); }
                              }
                            />
                          )
                        ) : (
                          <input
                            type="text"
                            id="referid"
                            placeholder="Referral Address"
                            value={referral}
                            className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text"
                            onChange={(e) => { setreferral(e.target.value); }

                            }
                          />
                        )}
                        <label htmlFor="floatingEmail" className="light-bg dark-text">Referral Address</label>
                      </div>
                    </form>{" "}
                    <h5 className="text-center new-pink1 rajdhani-600 py-2">
                      Min: $50 Max $5000
                    </h5>
                    <form className="form-style-floating style2 text-center mt-2">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text "
                          id="floatingPass"
                          placeholder="Amount"
                          value={amount}
                          onChange={(e) =>
                            setamount(e.target.value)
                          }
                        />
                        <label htmlFor="floatingPass" className="dark-text light-bg ">Amount</label>
                      </div>
                    </form>
                    <div>
                      <div className="card w-100 p-4 mt-3 light-bg border  rounded-10 flex-column d-flex justify-content-center  ">
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text d-flex mb-2">
                          Stake  <span className="ms-auto  rajdhani-700 dark-text">{total_tokens} {process.env.REACT_APP_TICKER}</span>
                        </h6>
                        <h6 className="text-gray-900 rajdhani-600 lh-1 mb-0 mt-1 text-center font-md dark-text d-flex  b-2">
                          Token Rate :
                          <span className="ms-auto  rajdhani-700 dark-text">${tokenrate}</span>
                        </h6>
                      </div>
                    </div>
                    {hash ? (
                      <div className="p-3 text-center rounded-10 text-gray-800 rajdhani-600 light-bg dark-text">
                        Hash :{" "}
                        <a
                          href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
                          target="_blank"
                          rel="noreferrer"
                          className="rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text"
                        >
                          #: {formatAddress(hash)}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex text-white rajdhani-600 text-uppercase btn-block pt-3 justify-content-center">
                      <input type="checkbox" name="tanc" id="tc" onChange={(event) => settandc(event.target.checked)} />
                      &nbsp; Accept Terms and Conditions
                    </div>
                    <form className="form-style-floating style2 text-center mt-2">
                      {trdone ? (
                        <div className="text-center">
                          <img
                            src="/assets/images/loader.gif"
                            alt="loading"
                            style={{ width: '100px' }}
                          />
                        </div>
                      ) : (

                        <button
                          type="button"
                          className="btn btn-lg bg-current text-gray-900 rajdhani-600 text-uppercase btn-block mb-0 rounded-10"
                          onClick={() => onDeposit()}
                        >
                          Stake
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
