import React from "react";

export default function FaqHero() {
  return (
    <div className="bg-black-new">
      <div className="container pt-150 pb-75 ">
        <div className="row">
          <div className="col-12 page-nav justify-content-center d-flex">
            <h2 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 display1-size dark-text">
            FAQs
            </h2>
          </div>
     
        </div>
      </div>
    </div>
  );
}
