import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProjectContext } from "../../ProjectContext";

export default function Header() {
  const { theme, toggleTheme, account, connectMetamask } = useContext(ProjectContext);

  const currentPath = window.location.pathname;

  return (
    <div>
      <header className="header-wrapper w-100">
        <nav className="navbar navbar-expand-lg navbar-light border-0">
          <div className="container posr">
            <Link className="navbar-brand light-logo" to="/">
              <img
                src="assets/images/hero/logo_white.svg"
                alt="logo"
                width={230}
              />
            </Link>
            <Link className="navbar-brand dark-logo" to="/">
              <img
                src="assets/images/hero/logo_black.svg"
                width={230}
                alt="logo"
              />
            </Link>
            <button
              className="navbar-toggler collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0" id="navbar-main">
                <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                  <Link className={`${currentPath === "/"
                    ? "nav-link header-ac  "
                    : "nav-link dark-text text-gray-900  "
                    }`} to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link
                    className={`${currentPath === "/governance"
                      ? "nav-link header-ac  "
                      : "nav-link dark-text text-gray-900  "
                      }`}
                    to="/governance"
                  >
                    Governance
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children">
                  <Link className={`${currentPath === "/farming"
                    ? "nav-link header-ac  "
                    : "nav-link dark-text text-gray-900  "
                    }`} to="/farming">
                    Farming
                  </Link>
                </li>
                <li className="nav-item menu-item-has-children menu-item-has-megamenu">
                  <Link className={`${currentPath === "/trading"
                    ? "nav-link header-ac  "
                    : "nav-link dark-text text-gray-900  "
                    }`} to="/trading">
                    Trading
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    to="/nfts"
                    className={`${currentPath === "/nfts"
                      ? "nav-link header-ac  "
                      : "nav-link dark-text text-gray-900  "
                      }`}
                  >
                    NFTs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link

                    to="/ecosystem"
                    className={`${currentPath === "/ecosystem"
                      ? "nav-link header-ac  "
                      : "nav-link dark-text text-gray-900  "
                      }`}
                  >
                    Ecosystem
                  </Link>
                </li>
              </ul>
              {account ?
                <Link
                  to="/dashboard"
                  className="btn btn-lg menu-btn border-0 text-white rajdhani-600 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"
                >
                  Dashboard
                </Link> :
                <a
                  href="#"
                  className="btn btn-lg menu-btn border-0 text-white rajdhani-600 rounded-6 bg-gray-900 px-4 font-sm text-uppercase"
                  onClick={() => connectMetamask()}
                >
                  {" "}
                  Connect Wallet
                </a>}
              {/* <a
                href="dashboard-history.html"
                className="ms-2 btn menu-btn btn-noti"
              >
                <i className="bi-bell font-md m-0" />
                <span className="dot-count bg-current rajdhani-600">6</span>
              </a> */}
              <a
                className="ms-2 menu-btn btn-toggle-dark btn btn-icon btn-round"
                checked={theme === "light-mode"}
                id="themeToggle"
                onClick={() => toggleTheme()}
              >
                <i className="bi-brightness-low font-xl m-0" />
              </a>
            </div>
            {account ?

              <Link
                to="/dashboard"
                className="btn btn-md header-btn text-gray-800 rajdhani-700 rounded-6 bg-gray-900  px-4 font-sm text-uppercase  text-white"
              >
                Dashboard
              </Link>
              :
              <a
                href="#"
                id="connetw"
                className="btn btn-md header-btn text-gray-800 rajdhani-700 rounded-6 bg-gray-900  px-4 font-sm text-uppercase  text-white"
                onClick={() => connectMetamask()}
              >
                {" "}
                Connect Wallet
              </a>
            }
            <a
              className="ms-2 mt-1 header-btn bg-transparent border-white btn-toggle-dark btn btn-icon btn-round"
              checked={theme === "light-mode"}
              id="themeToggle"
              onClick={() => toggleTheme()}
            >
              <i className="font-xl dark-text m-0 bi-brightness-low" />
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
}
