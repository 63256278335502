import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  const currentPath = window.location.pathname;

  return (
    <div>
      <div className="footer-wrapper pb-0 black-bg">
        <div className="container-large">
          <div className="row">
            <div className="col-lg-4 col-md-10 col-12">
              <a className="navbar-brand light-logo" href="#">
                {" "}
                <img
                  src="assets/images/hero/logo_white.svg"
                  width={230}
                  alt="logo"
                />
              </a>
              <a className="navbar-brand dark-logo" href="#">
                <img
                  src="assets/images/hero/logo_black.svg"
                  width={230}
                  alt="logo"
                />
              </a>
              <p className="mt-3 mb-4 pe-4 rajdhani-600 font-sm lh-24 text-gray-800 dark-text">
                Start your trading journey using our exclusive tools & product
                suits.
              </p>
              <ul className="d-flex flex-wrap gap-3 social-icon">
              <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://x.com/onmaxprotocol"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-twitter text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://www.facebook.com/people/OnmaxProtocol/61555490522388/"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-facebook text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://www.instagram.com/onmaxprotocol/"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-instagram text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://t.me/+Q-IBiQwK51JmNDg8"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-telegram text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://www.reddit.com/user/onmaxprotocol/"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-reddit text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
                <li className="list-group-item p-0 bg-transparent border-0">
                  <a
                    href="https://www.youtube.com/@onmaxprotocol"
                    target="_blank"
                    className="btn-icon bg-transparent rounded-0 btn btn-md btn-icon"
                  >
                    <i className="bi bi-youtube text-gray-700 dark-text font-md m-0" />
                  </a>
                </li>
               
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-6 lg-mt-3 ms-auto">
              <h4 className="text-gray-900 dark-text h6 rajdhani-700 mb-4 mt-1 text-uppercase">
                Products
              </h4>
              <ul className="list-group gap-2">
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    className={`${
                      currentPath === "/bots"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                    href="/bots"
                  >
                    Bots
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/farming"
                    className={`${
                      currentPath === "/farming"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    Farming
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/staking"
                    className={`${
                      currentPath === "/staking"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    Staking
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/nfts"
                    className={`${
                      currentPath === "/nfts"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    NFTs
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-6 lg-mt-3">
              <h4 className="text-gray-900 dark-text h6 rajdhani-700 mb-4 mt-1 text-uppercase">
                Quick Links
              </h4>
              <ul className="list-group gap-2">
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="#"
                    className="rajdhani-600 text-gray-800 dark-text font-sm"
                  >
                    dApp
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="#"
                    className="rajdhani-600 text-gray-800 dark-text font-sm"
                  >
                    Purchase Bot
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="#"
                    className="rajdhani-600 text-gray-800 dark-text font-sm"
                  >
                    Buy OM
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-3 col-6 lg-mt-3 ms-auto">
              <h4 className="text-gray-900 dark-text h6 rajdhani-700 mb-4 mt-1 text-uppercase">
                Support
              </h4>
              <ul className="list-group gap-2">
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/community"
                    className={`${
                      currentPath === "/community"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    Community
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/faq"
                    className={`${
                      currentPath === "/faq"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    FAQ
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="#"
                    className="rajdhani-600 text-gray-800 dark-text font-sm"
                  >
                    Docs
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-6 lg-mt-3">
              <h4 className="text-gray-900 dark-text h6 rajdhani-700 mb-4 mt-1 text-uppercase">
                Company
              </h4>
              <ul className="list-group gap-2">
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/about-us"
                    className={`${
                      currentPath === "/about-us"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    About Us
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="https://onmax.gitbook.io/onmax-whitepaper"
                    target="_blank"
                    className="rajdhani-600 text-gray-800 dark-text font-sm"
                  >
                    Whitepaper
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/terms-of-use"
                    className={`${
                      currentPath === "/terms-of-use"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    Terms of Use
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    href="/privacy-policy"
                    className={`${
                      currentPath === "/privacy-policy"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="list-group-item bg-transparent border-0 p-0">
                  <a
                    className={`${
                      currentPath === "/disclaimer"
                        ? "rajdhani-600 text-gray-800 dark-text font-sm new-pink1  "
                        : "rajdhani-600 text-gray-800 dark-text font-sm  "
                    }`}
                    href="/disclaimer"
                  >
                    Disclaimer
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12 mt-75">
              <p className="py-4 text-center border-footer rajdhani-600 text-gray-900 dark-text font-sm mb-0">
                Copyright © {year} - Onmax - All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
