import React from "react";

export default function NftIm() {
  return (
    <div>
      <div className="feature-wrapper black-bg ">
        <div className="container pt-100">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-dark-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/nft/nft4.mp4" type="video/mp4" />
              </video>
              <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-light-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/nft/dark-nft4.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="col-lg-6 col-md-12 ms-auto md-mt-4">
              <h2 className="text-gray-900 dark-text lh-3 mb-4 rajdhani-600 h1 pe-lg-5">
                A collection of 100,000 Unique NFTs
              </h2>
              <h6 className="text-gray-700 dark-text rajdhani-500 lh-30 pe-lg-5 me-lg-5">
                Onmax's NFT collection, consisting of 100,000 unique NFTs,
                offers more than just digital assets — it represents a gateway
                to decentralized finance (DeFi). These NFTs integrate multiple
                utilities, including access to trading bots, staking
                opportunities, and premium subscriptions, creating a
                multi-faceted experience for traders. By combining DeFi
                functionalities with NFT ownership, Onmax delivers a powerful
                blend of financial tools and exclusive benefits.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
