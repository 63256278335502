import React, { useContext, useState } from "react";
import { ProjectContext } from "../../ProjectContext";
import { useNavigate } from "react-router-dom";

export default function Viewer() {
  const { toastError, toastSuccess, setaccount } = useContext(ProjectContext);
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async () => {
    if (!oAddr) {
      toastError("Enter address")
      return
    }
    toastSuccess("Viewer activated")
    setaccount(oAddr)
    navigate('/dashboard')
  };
  return (
    <div>
      <div className="dash-main-content ">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="nft-wrapper py-5">
              <div className="container portfolio-grid vh-100">
                <div className="row justify-content-center">
                  <div className="col-lg-8 text-center d-flex justify-content-center gap-2 pb-4 ">
                    <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                      View
                    </h2>
                  </div>
                  <div className="col-md-12 mt-2 mb-3 portfolio-tab style2">
                    <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 light-bg  w-100">
                      <div className="d-flex text-start flex-column mt-5">
                    
                        <form className="form-style-floating style2 text-center mt-4 ">
                          <div className="form-floating mb-3">


                            <input
                              type="text"
                              id="referid"
                              value={oAddr}
                              className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text"
                              onChange={(e) => { setoAddr(e.target.value); }} />

                            <label htmlFor="floatingEmail" className="light-bg dark-text">Address</label>
                          </div>
                        </form>{" "}

                        <form className="form-style-floating style2 text-center mt-2">

                          <button
                            type="button"
                            className="btn btn-lg bg-current text-gray-900 rajdhani-600 text-uppercase btn-block mb-0 rounded-10"
                            onClick={() => getDta()}
                          >
                            Boooom
                          </button>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
