import React from "react";

export default function Collection() {
  return (
    <div>
      <div className="feature-wrapper py-5 black-bg">
        <div className="container mb-5">
          <div className="row justify-content-center pb-5 pt-lg-4">
            <div className="col-lg-8 text-center page-nav">
              <h2 className="text-gray-900 dark-text lh-2 mb-2 mt-0 rajdhani-600 h1">
              Futuristic Integrations Ahead
              </h2>
              <p className="dark-text text-gray-800 rajdhani-600 font-sm px-5">
              Onmax ecosystem is awaiting for the most cherished & promised integrations including yield-bearing NFTs, tokenized subscriptions, & AI-powered crypto chat bots. 
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr">
              <div className="img-dark-none">
             <img src="assets/images/hero/nft_final_icon.gif" height={100} width={100} alt="" />
             </div>
             <div className="img-light-none">
             <img src="assets/images/hero/nft_icon.gif" height={100} width={100} alt="" />
              </div>
                <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                NFT Collection
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                Acquire yield-bearing Onmax NFTs to unlock exclusive access to our ecosystem products. 

                </p>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr">
             <div className="img-dark-none">
             <img src="assets/images/hero/subscribe_icon_final.gif" height={100} width={100} alt="" />
             </div>
             <div className="img-light-none">
             <img src="assets/images/hero/subscribe_icon.gif" height={100} width={100} alt="" />
              </div>
                <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                PLUS Subscriptions 
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                Onmax Plus is a badge that users can buy & become eligible for our premium DeFi trading materials. 

                </p>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="icon-div d-flex flex-column text-center posr">
             <div className="img-dark-none">
             <img src="assets/images/hero/chat_bot_final_icon.gif" height={100} width={100} alt="" />
             </div>
             <div className="img-light-none">
             <img src="assets/images/hero/chatbot_icon.gif" height={100} width={100} alt="" />
              </div>
                <h4 className="h5 text-gray-900 dark-text rajdhani-600">
                Chat Bots
                </h4>
                <p className="dark-text text-gray-800 rajdhani-600 font-sm px-lg-4">
                Our trading bots are convertible chatbots that help traders amplify their trading strategies. 

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
