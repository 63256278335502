import React from "react";
import Footer from "./Coman/Footer";
import Header from "./Coman/Header";

export default function Disclaimer() {
  return (
    <>
      <Header />
  <div className="bg-black-new">
  <div className="container pt-150 pb-75">
        <div className="row">
          <div className="col-12 page-nav justify-content-center d-flex">
            <h2 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 display1-size dark-text pb-3">
              Disclaimer and Risk Statement
            </h2>
          </div>
        </div>
        <div className="pt-md-5">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Introduction
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Onmax is a decentralized finance (DeFi) platform that provides
            various services such as trading bots, staking, yield farming, NFTs,
            and more. While Onmax is designed to help users manage their
            cryptocurrency assets and access decentralized financial solutions,
            it is important to recognize that interacting with blockchain
            technology and decentralized systems carries inherent risks.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            This document outlines the risks associated with using Onmax, which
            users must fully understand before participating in any activities
            on the platform.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            General Risk of Cryptocurrency Investments
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Cryptocurrency markets are known for their high volatility and
            unpredictable price movements. The value of digital assets can
            fluctuate drastically due to market trends, regulatory changes, and
            technological developments.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            As a result, users who invest or participate in activities on Onmax
            may experience significant financial gains or losses. It is
            important to note that Onmax does not guarantee any returns on your
            investments, and users should only invest amounts they are willing
            to lose.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Market Risk
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Investments in cryptocurrency and DeFi products are subject to
            market risk, meaning the value of assets may decrease or increase
            based on changes in the market. Market risk is driven by several
            factors including global events, technological advancements, legal
            or regulatory changes, and sentiment within the crypto community.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax cannot control or predict market movements and as such, cannot
            be held responsible for any losses resulting from market volatility.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Guarantee of Funds
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Onmax strives to provide a secure and user-friendly platform for
            managing crypto assets. However, we do not provide any guarantees
            regarding the 100% safety or performance of users' funds. All
            financial decisions and transactions are made at the user’s
            discretion, and users are responsible for managing their own risk.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Cryptocurrencies and DeFi products do not come with
            government-backed insurance (such as FDIC in traditional banking),
            and there is always a possibility of losing funds.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Technological and Operational Risks
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            As a decentralized platform, Onmax relies on smart contracts,
            blockchain technology, and digital infrastructure. There is always
            the potential for technical failures, bugs, or security
            vulnerabilities, which could impact the functionality of the
            platform or lead to the loss of funds. Additionally, blockchain
            networks can experience congestion, delays, or outages, which may
            result in failed transactions or losses during high-traffic periods.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax makes every effort to secure its infrastructure and mitigate
            risks through regular audits and updates, but users must understand
            that technological failures can and do occur, and these failures may
            result in lost assets or delayed access to services.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Risk of Security Breaches
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            While Onmax implements industry-standard security measures to
            protect user data and assets, the platform and its users remain
            exposed to potential cyberattacks, phishing attempts, or
            unauthorized access. Hackers may target both the platform and
            individual user accounts to steal assets or sensitive information.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Users are responsible for maintaining their own security by
            safeguarding private keys, using secure wallets, and avoiding
            phishing schemes. Onmax does not have control over user-operated
            wallets and cannot recover funds lost due to unauthorized access or
            user error.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Regulatory and Legal Risk
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Cryptocurrency regulations vary from one jurisdiction to another,
            and legal frameworks around digital assets are constantly evolving.
            Changes in local laws or government policies could affect the
            ability of users to participate in DeFi activities on Onmax or even
            access the platform. In extreme cases, assets may be frozen,
            transactions blocked, or platforms forced to suspend operations due
            to regulatory actions.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Users must ensure they are in compliance with the laws and
            regulations of their country before using Onmax. Onmax is not liable
            for any legal consequences users may face from interacting with
            digital assets or DeFi services, and users are advised to seek legal
            counsel if they are unsure about the legal implications of using
            cryptocurrency platforms.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Risk of Token and Smart Contract Failures
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Onmax uses smart contracts to execute various financial services
            such as trading, staking, and yield farming. While smart contracts
            are designed to be secure and tamper-proof, they are still software,
            and bugs or vulnerabilities may exist. If a smart contract fails, it
            may result in a loss of assets or failed transactions. Onmax works
            diligently to audit and verify its smart contracts, but no system is
            completely immune to failure, and users must be aware that risks
            remain.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Additionally, any tokens issued by Onmax, including its native OM
            token, are subject to the same technological and market risks. The
            value of tokens may fluctuate, and there is a possibility that
            tokens could lose value or become unusable due to unforeseen
            circumstances.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Risk of Technical Updates or Forks
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            Blockchain platforms occasionally undergo updates, improvements, or
            forks, which can affect users’ ability to interact with certain
            dApps or services. These forks or upgrades could lead to temporary
            disruptions or compatibility issues that may prevent users from
            accessing their assets or completing transactions on Onmax. Users
            should be prepared for these potential events and follow guidance
            provided by the Onmax team.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Platform-Specific Risks
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            As a decentralized platform, Onmax is not responsible for user
            errors, such as sending assets to the wrong address, using
            incompatible wallets, or failing to properly configure trading bots
            and other services. Any mistakes in interacting with the platform
            may result in the irreversible loss of funds. Users are strongly
            advised to double-check transaction details and ensure they are
            using supported wallets and protocols.
          </p>
        </div>
        <div className="pt-md-3">
          <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
            Final Thoughts and User Responsibility
          </h5>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
            By using Onmax, users acknowledge that they fully understand the
            risks involved in interacting with decentralized finance platforms
            and cryptocurrency investments. Participation in Onmax services is
            entirely at the user’s own risk, and users should carefully assess
            their risk tolerance before making any financial decisions.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            Onmax encourages users to stay informed, practice good security
            habits, and take responsibility for their actions on the platform.
            While we work hard to provide a secure and reliable experience, we
            do not assume liability for any losses or damages that may result
            from using the platform.
          </p>
          <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
            For more detailed information about the technical aspects, security
            measures, and any future updates, please refer to our official
            documentation and announcements.
          </p>
        </div>
      </div>
  </div>
      <Footer />
    </>
  );
}
