import React from "react";
import Sidebar from "../../Coman/Sidebar";
import RoyaltyFundHero from "./RoyaltyFundHero";

export default function RoyaltyFund() {
  return (
    <div>
      <Sidebar />
      <div className="dash-main-content vh-100">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="card bg-transparent border-0 pt-4 pb-3 px-0">
              <div className="card-body p-0 border-0 shadown-none d-flex flex-row mb-2">
                <h5 className="text-gray-900 dark-text font-xl dark-text rajdhani-600 mb-0 lh-24">
                  Royalty Fund
                </h5>
             
              </div>
              <RoyaltyFundHero/>
       
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
