import React from "react";

export default function TradingBox() {
  return (
    <div>
      <div className="howitwork-wrapper py-5 bg-black-new">
        <div className="container-wide py-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 text-center justify-content-center gap-2 pb-75">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                Utilities Go Beyond Just Trading!
              </h2>
              <p className="rajdhani-600 dark-text ps-md-2 font-md lh-32 text-gray-900 w-lg-75 mt-2 pe-lg-5">
                Onmax’s bots go beyond trading as they power additional
                utilities including PLUS Subscription for users, NFT
                integrations, tailoring strategies for diverse investment goals
                & more.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                  <img
                    src="assets/images/tra/plus_subscription.svg"
                    height={30}
                    width={30}
                    alt=""
                  />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                PLUS Subscription
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Grants access to exclusive trading signals and materials,
                enhancing the user experience and profitability.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/tra/nft_integration.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>

              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                NFT Integration
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Bots are linked with Onmax NFTs, each offering different profit
                caps and margin levels for personalized trading strategies.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
              <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                <img
                  src="assets/images/tra/realtime_data_tracking.svg"
                  height={30}
                  width={30}
                  alt=""
                />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Real-Time Data Tracking
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Bots continuously optimize trades based on market data, ensuring
                efficiency and maximizing returns.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 lg-mb-3 howitwork-div d-flex flex-column justify-content-center text-center">
            <div className="rounded-pill new-pink-bg btn btn-icon btn-xl mx-auto  text-white font-lg  ">
                  <img
                    src="assets/images/tra/chat_bot.svg"
                    height={30}
                    width={30}
                    alt=""
                  />
              </div>
              <h6 className="rajdhani-600 mt-3 mb-2 h5 text-gray-900 dark-text">
                Chat BOT
              </h6>
              <p className="text-gray-600 dark-text rajdhani-600 mt-0 font-sm px-xl-3 lh-26 mb-0">
                Each bot could be used as a Crypto Chat bot that helps user
                understand the broader crypto market & the evolving DeFi
                industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
