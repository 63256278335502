import React from "react";

export default function Projects() {
  return (
    <div>
      <div className="roadmap-wrap pt-100 pb-100 black-bg">
        <div className="container ">
          <div className="row justify-content-center mb-50">
            <div className="col-lg-8 text-center">
              <h2 className="text-gray-900 rajdhani-600 h1 mb-0 dark-text">
                Explore our all products & services
              </h2>
              <p className="px-md-5 mt-2 text-gray-800 dark-text rajdhani-600 font-sm">
                Unlock the full potential of your crypto assets with Onmax's
                diverse range of services. From automated trading bots to
                staking and liquidity mining, we offer tools to optimize your
                earnings.
              </p>
            </div>
          </div>
          <div
            className="row"
            // style={{ backgroundImage: "url(assets/images/hero/lines.gif" ,backgroundRepeat:"no-repeat",backgroundSize:"cover" }}
          >
             <div className="col-12 d">
            {/* <img src="assets/images/hero/lines.gif" className="gif-line" alt="" /> */}
            <video playsinline="playsinline" muted="muted"  preload="yes" autoplay="autoplay" loop="loop" id="vjs_video_739_html5_api" class=" video-js gif-line img-dark-none" data-setup='{"autoplay":"any"}'>
    		<source src="assets/images/hero/lines_1.mp4"  type="video/mp4" />
      </video>
      <video playsinline="playsinline" muted="muted"  preload="yes" autoplay="autoplay" loop="loop" id="vjs_video_739_html5_api" class=" video-js gif-line img-light-none" data-setup='{"autoplay":"any"}'>
    		<source src="assets/images/hero/dark-lines_1.mp4"  type="video/mp4" />
      </video>
            </div>
            <div className="col-lg-4 pt-lg-5">
              <div
                className="roadmap-div lg-mt-3 rounded-6 bg-white dark-bg shadow-lg z-index-10 posr mx-auto w-250 mt-lg-5"
                style={{ zIndex: "99" }}
              >
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/trading_bots.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-dark-none"
                  />
                  <img
                    src="assets/images/hero/trading_bots-dark.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-light-none"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Trading Bots
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Automated bots execute profitable trades based on market
                    analysis.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
              <div className="roadmap-div lg-mt-3 roadmap-divbg6 rounded-6 bg-white dark-bg shadow-lg z-index-10 posr mx-auto w-250 mt-lg-5">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/staking.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3  img-dark-none"
                  />
                  <img
                    src="assets/images/hero/staking-dark.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-light-none"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Staking
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Earn passive rewards by locking up your crypto assets.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="roadmap-div roadmap-divbg lg-mt-3 rounded-6 bg-white dark-bg shadow-lg z-index-5 mx-auto w-250">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/farming.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-dark-none"
                  />
                  <img
                    src="assets/images/hero/farming_1-dark.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-light-none"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Farming
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Boost your yield by providing liquidity to various DeFi
                    platforms.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right  text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
              <div className="roadmap-div roadmap-divbg2 lg-mt-3 rounded-6 bg-white dark-bg shadow-lg z-index-5 mx-auto w-250 mt-lg-5">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/liquidity_mining.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-dark-none"
                  />
                   <img
                    src="assets/images/hero/liquidity_mining-dark.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-light-none"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Liquidity Mining
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Earn rewards by supplying liquidity to decentralized
                    exchanges.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right text-white text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pt-lg-5">
              <div className="roadmap-div roadmap-divbg4 lg-mt-3 rounded-6 bg-white dark-bg shadow-lg z-index-10 posr mx-auto w-250 mt-lg-5">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/call_&_signals.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Calls & Signals
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    Get real-time trading recommendations and market insights.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right text-white  text-gray-900  text-end font-md  "></i>
                </div>
              </div>
              <div className="roadmap-div lg-mt-3 rounded-6 bg-white dark-bg shadow-lg z-index-10 posr mx-auto w-250 mt-lg-5">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/hero/crypto_chatbots.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-dark-none"
                  />
                  <img
                    src="assets/images/hero/crypto_chatbots_1.svg"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3 img-light-none"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                    Crypto ChatBots
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                    AI-powered chatbots that assist with trading, market
                    updates, and queries.
                  </p>
                </div>
                <div className="d-flex flex-row justify-content-end py-2 px-4 bg-black">
                  <i class="bi bi-arrow-right text-white  text-gray-900  text-end font-md  "></i>
                </div>
              </div>
            </div>
       
          </div>
        </div>
      </div>
    </div>
  );
}
