import { Line } from "rc-progress";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProjectContext } from "../../../ProjectContext";

export default function DashboardHero() {
  const { account, dbuser, copyaddress, formatAddress } =
    useContext(ProjectContext);

  return (
    <div>
      <div className="dash-main-content">
        <div className="middle-sidebar-bottom">
          {/* middle wrap */}
          <div className="middle-sidebar-left">
            <div
              className="card p-0 border-0 rounded-15 px-4 pt-4 bg-current bg-size-cover bg-no-repeat bg-image-center box-new-sh"
              // style={{ backgroundImage: "url(assets/images/banner-bg-7.png)" }}
              style={{
                background:
                  "linear-gradient(105deg, rgb(230, 62, 230), rgb(55 42 181)",
              }}
            >
              <div className="row align-items-end">
                <div className="col-md-6 py-md-5 ps-md-5">
                  <h5 className="text-gray-900 h1 rajdhani-500 mb-1">
                    Stake Funds Now & Start Earning
                  </h5>
                  <p className="font-sm lh-28 rajdhani-600 text-gray-900 mb-1">
                    Maximize your crypto earnings effortlessly by staking with
                    Onmax & leveraging advanced trading bots for consistent
                    rewards.
                  </p>
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div>
                      <h5
                        className="rajdhani-600 mt-3"
                        onClick={() => copyaddress(account)}
                      >
                        Address: <span>{formatAddress(account)}</span>{" "}
                        <i className="feather-copy font-md ms-2 cursor-pointer "></i>
                      </h5>
                      <h5
                        className="rajdhani-600"
                        onClick={() =>
                          copyaddress(
                            dbuser !== null ? dbuser.ref_address : "no referral"
                          )
                        }
                      >
                        Referral Address:{" "}
                        <span>
                          {" "}
                          {dbuser !== null
                            ? formatAddress(dbuser.ref_address)
                            : "no referral"}
                        </span>{" "}
                        <i className="feather-copy font-md  ms-2 cursor-pointer"></i>
                      </h5>
                    </div>
                    <div>
                      <h5 className="rajdhani-600">Rank: {dbuser
                        ? dbuser.rank === null
                          ? "No Rank"
                          : dbuser.rank
                        : "-"}</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-1" />

                <div className="col-md-4 ">
                  <img
                    src="assets/images/dash/bot_hero_image_2.png"
                    alt="nft"
                    className="img-fluid"
                    style={{ height: "260px" }}
                  // height={500}
                  />
                </div>
              </div>
            </div>
            {dbuser ? dbuser.restake ?
              <h5 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 dark-text pt-4 text-center blink">⭕Restake your account</h5> : '' : ''}
            <div className="row mt-3">
              <div className="col-12">
                <div className="p-4 card-body light-bg dark-border border border-light border-2 rounded-10 box-new-sh">
                  <div className="row justify-content-around">
                    <div className="col-lg-2 col-md-3 col-sm-6 mt-3 mt-md-0">
                      <Link
                        to="/stake"
                        className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 font-sm text-uppercase box-new-sh"
                      >
                        Stake Now
                      </Link>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6  mt-3 mt-md-0">
                      <Link
                        to="/withdrawal"
                        className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 font-sm text-uppercase"
                      >
                        Withdrawal
                      </Link>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6  mt-3 mt-md-0">
                      <a
                        href="#"
                        onClick={() => copyaddress(`${process.env.REACT_APP_LINK}stake/${account}`)}
                        className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 font-sm text-uppercase"
                        title="Click to copy sponser link"
                      >
                        Sponsor Link
                      </a>
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-6  mt-3 mt-md-0">
                      <a
                        href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x873bE1D2FeD62dc488A754f387e17f01E9c92628"
                        target="_blank"
                        className="btn btn-lg text-gray-900 rajdhani-700 rounded-6 bg-current border-current border-2 font-sm text-uppercase"
                      >
                        Buy OM
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/staking_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Staking Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.staking_rew).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/fox_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Fox Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ 0
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/direct_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Direct Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.direct_rew).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/community_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Community Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.level_rew).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/rank_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Rank Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.rank_rew).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/royalty_club_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Royalty Club Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.r_club_rew).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/award_and_reward.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Award and Reward
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ 0
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3">
                <div className="card-body mt-3 light-bg dark-border border border-light border-2 rounded-10 p-3 box-new-sh">
                  <div className="d-flex mb-4 justify-content-between">
                    <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2 dark-text">
                      Reward Capability
                    </h6>
                    <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2 dark-text">
                      3x
                    </h6>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-lg-12" title={`${dbuser ? dbuser.x_cper : 0}%`}>
                      <Line
                        percent={dbuser ? dbuser.x_cper : 0}
                        strokeWidth={1}
                        strokeColor="#eb6502"
                        trailWidth={1}
                        trailColor="#005c9f"
                      />
                    </div>
                    <div className="col-lg-12 d-flex justify-content-between ">
                      <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2 dark-text">$0</h6>
                      <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2 dark-text">${dbuser ? dbuser.xt_income : 0}</h6>
                      <h6 className="text-gray-900 rajdhani-700 m-02 font-md pb-2 mt-2 dark-text">${dbuser ? dbuser.cap_income : 0}</h6>
                    </div>
                    {/* <div className="col-lg-4">
                      {" "}
                      <CountdownTimer targetDate={"2024-10-15T15:00:00"} />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/current_staking.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Current Staking/Bot
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.c_staking).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/total_staking.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total Staking/Bot
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_staking).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/direct_team.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Direct Team
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser ? dbuser.d_team : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/team.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Team
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser ? dbuser.team : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/team_business.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Team Business
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.team_business).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/a_leg.svg" height={30} alt="" />
                    </div>                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Strong Leg
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.s_leg).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/other_leg.svg" height={30} alt="" />
                    </div>                   </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Other Leg
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.o_leg).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/a_leg.svg" height={30} alt="" />
                    </div>                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Required Strong Leg
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.ra_leg).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/a_leg.svg" height={30} alt="" />
                    </div>                      </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Required Other Leg
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.ro_leg).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/required_team.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Required Strong Team
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser ? dbuser.ra_team : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/required_team.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Required Other Team
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser ? dbuser.ro_team : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/current_rank.svg" height={30} alt="" />
                    </div>
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Current Rank
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser
                        ? dbuser.rank === null
                          ? "No Rank"
                          : dbuser.rank
                        : "-"}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/next_rank.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Next Rank
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      {dbuser ? dbuser.next_rank : 0}
                    </h2>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                  <div className="rounded-pill bg-current btn btn-icon btn-md">
                  <img src="assets/images/dash/icon/balance.svg" height={30} alt="" />
                  </div>  
                  </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Balance
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? dbuser.balance : 0}
                    </h2>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/total_withdrawal.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total Withdrawal
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_withdrawal).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/total_withdrawal.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total SP Withdrawal
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_staking_withdrawal).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/icon/total_withdrawal.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total MP Withdrawal
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_mining_withdrawal).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/total_sp.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total SP
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_reward_staking).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/total_sp.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Balance SP
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.staking_balance).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/total_mp.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Total MP
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.t_reward_mining).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12 pe-0 mt-2">
                <div className="d-flex p-4 border-light border-2 border-end rounded-10 border box-new-sh">
                  <div className="col-3  pe-0">
                    <div className="rounded-pill bg-current btn btn-icon btn-md">
                      <img src="assets/images/dash/total_mp.svg" height={30} alt="" />
                    </div>                    </div>
                  <div className="col-9 ps-0 ms-auto text-center ">
                    <h6 className="text-gray-700 rajdhani-600 mb-0 lh-18 ms-0 font-sm dark-text">
                      Balance MP
                    </h6>
                    <h2 className="rajdhani-700 font-xxxl ls-1 text-gray-900 mb-0 dark-text">
                      $ {dbuser ? Number(dbuser.mining_balance).toFixed(2) : 0}
                    </h2>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
