import React from "react";

export default function NftMap() {
  return (
    <div>
      <div className="roadmap-wrapper pt-5 pb-5 bg-black-new">
        <div className="container-large py-lg-5">
          <div className="row mb-50">
            <div className="col-md-12 ">
              <h2 className="h1 lh-2 mb-0 text-gray-900 rajdhani-600 dark-text text-center">
              Advantages for having Onmax NFTs
              </h2>
            
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/nft/diversify_investments.png"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                  Diversify investments
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                  Users can hold NFTs as assets with intrinsic value, benefiting from both their utility and potential price appreciation in secondary markets.
                  </p>
                </div>
                <div className=" py-2 px-4 d-flex justify-content-end bg-current">
                
                  <i className="bi bi-arrow-right text-white text-gray-900  text-end font-md  " />
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-10 posr ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg pb-5">
                  <img
                    src="assets/images/nft/engage_with_the_platform.png"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                  Engage with the platform

                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                  NFTs provide additional layers of interaction, from governance participation to community engagement, enriching the user experience.
                  </p>
                </div>
                <div className=" py-2 px-4 d-flex justify-content-end bg-current">
                
                <i className="bi bi-arrow-right text-white text-gray-900  text-end font-md  " />
              </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-5 ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/nft/maximize_defi_strategies.png"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                  Maximize DeFi strategies
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-22 mb-0">
                  By tying NFTs to trading bots and premium features, Onmax ensures that users can make the most of the tools available, creating more personalized and data-driven investment strategies.

                  </p>
                </div>
                <div className=" py-2 px-4 d-flex justify-content-end bg-current">
                
                <i className="bi bi-arrow-right text-white text-gray-900  text-end font-md  " />
              </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-5 ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/nft/enhance_ownership_&_provenance.png"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                  Enhance ownership & provenance
                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                  Each NFT comes with a unique, immutable record on the blockchain, ensuring authenticity and enabling users to track the provenance of their assets, which fosters trust and transparency.
                  </p>
                </div>
                <div className=" py-2 px-4 d-flex justify-content-end bg-current">
                
                <i className="bi bi-arrow-right text-white text-gray-900  text-end font-md  " />
              </div>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="roadmap-div rounded-10 ovh bg-white dark-bg shadow-lg z-index-5 ">
                <div className="d-flex flex-column px-4 pt-3 pb-3 gap-2 bg-white dark-bg">
                  <img
                    src="assets/images/nft/unlock_liquidity_options.png"
                    alt="icon"
                    className="w-55 mb-2 ms-n2 mt-3"
                  />
                  <h4 className="text-gray-900 dark-text font-md rajdhani-700 mt-1 mb-1 text-uppercase">
                  Unlock liquidity options

                  </h4>
                  <p className="text-gray-900 dark-text rajdhani-500 font-sm lh-26 mb-0">
                  Users can utilize their NFTs as collateral for loans or other financial instruments, providing immediate capital access while retaining ownership of their digital asset.
                  </p>
                </div>
                <div className=" py-2 px-4 d-flex justify-content-end bg-current">
                
                <i className="bi bi-arrow-right text-white text-gray-900  text-end font-md  " />
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
