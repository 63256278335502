import React from "react";
import Footer from "./Coman/Footer";
import Header from "./Coman/Header";

export default function TermsofUse() {
  return (
    <div>
      <>
        <Header />
    <div className="bg-black-new">
    <div className="container pt-150 pb-75">
          <div className="row">
            <div className="col-12 page-nav justify-content-center">
              <h2 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 display1-size dark-text text-center pb-3">
                Terms of Use
              </h2>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2 text-center">
                Welcome to Onmax, a decentralized protocol designed for trading
                and managing crypto assets. By accessing or using our platform,
                you agree to comply with the following Terms of Use. These terms
                govern your use of Onmax, so please read them carefully. If you
                do not agree with these terms, you should refrain from using our
                services.
              </p>
            </div>
          </div>

          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              1. Acceptance of Terms
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              By using Onmax, you acknowledge that you have read, understood,
              and agree to these Terms of Use. You further agree to abide by all
              applicable laws and regulations related to the use of
              decentralized protocols and crypto assets. Onmax reserves the
              right to modify or update these terms at any time without prior
              notice. It is your responsibility to review these terms
              periodically.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              2. Decentralized Protocol Disclaimer
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Onmax is a decentralized protocol that facilitates interactions
              with various blockchain networks for trading and managing crypto
              assets. The platform operates without intermediaries, meaning
              transactions and operations are executed on-chain, directly
              between users. As such, Onmax does not control, manage, or own the
              underlying blockchain networks, nor does it hold any custody of
              your funds.
            </p>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  pt-2">
              You understand and acknowledge that by using Onmax, you are
              interacting with decentralized systems and assume all risks
              related to transactions, including market volatility, smart
              contract vulnerabilities, and potential loss of funds.
            </p>
            {/* <ul>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Service Providers :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  We may share your information with trusted third-party service
                  providers who assist us in delivering services, such as
                  payment processors and analytics providers
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Legal Compliance :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  We may disclose your information if required by law, to comply
                  with legal processes, or to protect our rights and the safety
                  of our users.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Business Transfers :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  In the event of a merger, acquisition, or sale of Onmax
                  assets, your information may be transferred as part of the
                  transaction.
                </p>
              </li>
            </ul> */}
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              3. Eligibility
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              To use Onmax, you must:
            </p>
            <div>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                {" "}
                <i class="bi bi-circle-fill font-xssss dark-text me-2"></i>
                Be at least 18 years old or the age of majority in your
                jurisdiction.
              </p>
            </div>
            <div>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                {" "}
                <i class="bi bi-circle-fill font-xssss dark-text me-2"></i>
                Have the legal authority to enter into a binding agreement.
              </p>
            </div>
            <div>
              <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                {" "}
                <i class="bi bi-circle-fill font-xssss dark-text me-2"></i>
                Comply with all local, state, national, and international laws
                regarding the use of crypto assets and decentralized protocols.
              </p>
            </div>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              You are solely responsible for ensuring that your use of Onmax
              complies with applicable laws.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              4. Use of the Platform
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              As a user of Onmax, you agree to the following:
            </p>
            <ul>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Self-Custody :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  You are responsible for the safekeeping of your private keys
                  and wallet credentials. Onmax does not have access to or
                  control over your funds.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Transaction Responsibility :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  You assume full responsibility for any transactions initiated
                  through the Onmax protocol. All transactions are irreversible
                  once submitted to the blockchain.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Market Risks :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  You understand that the value of crypto assets is volatile,
                  and using Onmax may expose you to significant financial risk.
                  You are solely responsible for managing and assessing your own
                  risks.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Compliance :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  You will not use Onmax for illegal activities, including money
                  laundering, terrorism financing, or any other criminal
                  actions.
                </p>
              </li>
            </ul>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              5. Fees and Costs
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Onmax does not charge platform fees; however, you may incur
              blockchain transaction fees (gas fees) or other third-party
              service fees associated with your use of decentralized networks.
              You are responsible for understanding and paying any fees incurred
              during the use of Onmax.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              6. No Custodial Services
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Onmax does not offer custodial services for crypto assets. You are
              responsible for the custody of any crypto assets used within the
              platform. This includes maintaining secure wallets and private
              keys to access and manage your funds. Onmax is not liable for any
              loss of assets due to hacking, theft, or loss of private keys.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              7. Risks of Decentralized Systems
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Using Onmax involves several inherent risks, including but not
              limited to:
            </p>
            <ul>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Smart Contract Risks :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  The platform operates on blockchain networks and smart
                  contracts that are subject to vulnerabilities or bugs. Onmax
                  cannot guarantee the functionality, security, or reliability
                  of third-party smart contracts.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Market Volatility :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  The value of crypto assets can fluctuate significantly. You
                  understand that crypto asset prices are highly volatile and
                  could result in significant financial losses.
                </p>
              </li>
              <li className="d-flex align-items-center gap-2">
                <i class="bi bi-circle-fill font-xssss dark-text "></i>
                <h6 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
                  Regulatory Risks :
                </h6>
              </li>
              <li>
                <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
                  The regulatory landscape for crypto assets is evolving. You
                  acknowledge that your use of Onmax may be subject to legal or
                  regulatory changes, which could impact your ability to use the
                  platform.
                </p>
              </li>
            </ul>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              8. Intellectual Property
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Onmax, including all content, trademarks, logos, and services, is
              the property of the Onmax team or its licensors. You are granted a
              limited, non-exclusive, non-transferable license to use the
              platform for personal or commercial purposes, provided that you
              comply with these terms.
            </p>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700 pt-2 ">
              You may not copy, modify, distribute, or reverse-engineer any
              aspect of the Onmax platform without explicit written consent.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              9. Termination
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              Onmax reserves the right to terminate or suspend access to the
              platform at any time, without notice, for any user who violates
              these Terms of Use or engages in unlawful activity. Upon
              termination, all provisions of these terms related to user
              responsibilities, intellectual property, and liability limitations
              will remain in effect.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              10. Limitation of Liability
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              To the fullest extent permitted by law, Onmax and its team shall
              not be liable for any direct, indirect, incidental, special,
              consequential, or punitive damages, including but not limited to
              loss of assets, trading losses, or damage to reputation arising
              from your use of the platform.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              11. Indemnification
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              You agree to indemnify and hold Onmax and its team harmless from
              any claims, losses, damages, liabilities, and expenses (including
              legal fees) arising out of or related to your use of the platform,
              breach of these terms, or violation of applicable laws.
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              12. Governing Law
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              These Terms of Use shall be governed and construed in accordance
              with the laws of [Jurisdiction]. Any disputes arising from or
              related to the use of Onmax will be subject to the exclusive
              jurisdiction of the courts of [Jurisdiction].
            </p>
          </div>
          <div className="pt-md-4">
            <h5 className="text-gray-900 dark-text lh-08 m-0 rajdhani-600 dark-text ">
              13. Contact Information
            </h5>
            <p className="rajdhani-600 dark-text font-sm  text-gray-700  ">
              If you have any questions or concerns about these Terms of Use,
              please contact us at:
            </p>
            <p className="rajdhani-600 dark-text  text-gray-700 d-flex gap-2 ">
              <span className="h6 dark-text">Email :</span>{" "}
              <a
                href="mailto:support@onmax.com"
                className="rajdhani-600 dark-text  font-sm   text-gray-70"
              >
                support@onmax.com
              </a>
            </p>
          </div>
          <div className="pt-md-4">
            <h6 className="dark-text">
              By using Onmax, you acknowledge that you have read, understood,
              and agree to be bound by these Terms of Use.
            </h6>
          </div>
        </div>
    </div>
        <Footer />
      </>
    </div>
  );
}
