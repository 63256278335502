import React from "react";

export default function Stay() {
  return (
    <div className="bg-black-new">
     <div className="container pb-100 ">
        <div className="row">
        <div className="col-12">
        <div
          className="subscribe-banner rounded-10 mt-125 p-md-5 p-4 "
          style={{ backgroundImage: 'url("assets/images/base.png")' }}
        >
          <div className="row">
            <div className="col-12">
              <h2 className="h1  text-gray-900 rajdhani-700 lh-1 m-0">
              Stay tuned as we continue to develop dApps & cater all your needs in DeFi! 


              </h2>
            </div>
    
          </div>
        </div>
      </div>
        </div>
     </div>
    </div>
  );
}
