import React from "react";

export default function Bots() {
  return (
    <>
      <div className="main-wrapper bg-image-cover bg-image-center">
        <div className=" mb-3">
          <div className="row mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div className="col-lg-8 col-md-10 col-sm-11 col-12">
                <div className="shadow-lg p-lg-4 p-3 text-center rounded-10 light-bg  w-100">
                  <div className="d-flex text-start flex-column mt-5">
                    <h1 className="rajdhani-700 mt-0 mb-0 text-gray-800 ls-0 lh-1 h2 dark-text">
                      Bots
                    </h1>
                    <form className="form-style-floating style2 text-center mt-4">
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control rounded-10 font-xsss text-gray-800 rajdhani-600 light-bg dark-text "
                          id="floatingEmail"
                          placeholder="Referral Address"
                        />
                        <label
                          htmlFor="floatingEmail"
                          className="light-bg  dark-text"
                        >
                          Referral Address
                        </label>
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 mt-2">
        <label className="form-label rajdhani-600 text-gray-700 ">
          Choose your account
        </label>
        <div className="d-flex flex-row gap-2 mb-3 flex-wrap justify-content-center ">
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-visa"
              defaultChecked
            />
            <label
              className="p-3 border-light dark-border border box-new-sh payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-visa"
            >
              <img src="assets/images/dash/yellow_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 dark-text text-gray-900 h6 rajdhani-600">
                $ 50
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Yellow Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card"
            />
            <label
              className="p-3 border-light dark-border border box-new-sh payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card"
            >
              <img src="assets/images/dash/yellow_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                $ 100
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Yellow Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-1"
            />
            <label
              className="p-3 border-light dark-border border box-new-sh payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-1"
            >
              <img src="assets/images/dash/yellow_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                $ 250
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Yellow Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-2"
            />
            <label
              className="p-3 border-light dark-border border box-new-sh payment-check check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-2"
            >
              <img src="assets/images/dash/brown_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                $ 500
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Brown Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-3"
            />
            <label
              className="p-3 border-light dark-border border payment-check box-new-sh check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-3"
            >
              <img src="assets/images/dash/brown_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                $ 1000
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Brown Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-4"
            />
            <label
              className="p-3 border-light dark-border border payment-check box-new-sh check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-4"
            >
              <img src="assets/images/dash/brown_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
                $ 2500
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Brown Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-4"
            />
            <label
              className="p-3 border-light dark-border border payment-check box-new-sh check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-4"
            >
              <img src="assets/images/dash/black_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
              Coming soon
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Black Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-4"
            />
            <label
              className="p-3 border-light dark-border border payment-check box-new-sh check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-4"
            >
              <img src="assets/images/dash/black_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
              Coming soon
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Black Fox
              </span>
            </label>
          </div>
          <div className="form-radio slot-w">
            <input
              type="radio"
              className="btn-check"
              name="bank"
              id="bank-card-4"
            />
            <label
              className="p-3 border-light dark-border border payment-check box-new-sh check-top btn-md btn-block rounded-10 d-flex flex-column position-relative border-2 border-light inter-600 text-gray-700 text-start justify-content-start"
              htmlFor="bank-card-4"
            >
              <img src="assets/images/dash/black_bot.png" alt="card" className="w-35" />
              <span className="mt-3 mb-0 h6 dark-text text-gray-900 rajdhani-600">
              Coming soon
              </span>
              <span className="rajdhani-500 dark-text text-gray-500 lh-24 mb-0 font-xs">
                Black Fox
              </span>
            </label>
          </div>
        </div>
      </div>
      <div>
        <div>
          <a
            href="#"
            className="btn btn-md btn-block text-gray-900 text-uppercase rajdhani-700 rounded-10 bg-current  font-xs mt-3 mb-4 box-new-sh"
          >
            Save
          </a>
        </div>
      </div>
    </>
  );
}
