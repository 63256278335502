import React from "react";

export default function Expertize() {
  return (
    <>
   <div className="pt-100 pb-100 bg-black-new">
   <div className="app-wrapper ">
        <div className="container ">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-6 col-lg-6">
            <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-dark-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/farm/start_farming_now_section_video.mp4" type="video/mp4" />
              </video>
              <video
                playsinline="playsinline"
                muted="muted"
                preload="yes"
                autoplay="autoplay"
                loop="loop"
                id="vjs_video_739_html5_api"
                class="video-js w-100 h-100 img-light-none"
                data-setup='{"autoplay":"any"}'
              >
                <source src="assets/images/farm/start_farming_now_section_video_03.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="col-xl-5 col-lg-6 pt-4 pb-4">
              <h4 className="rajdhani-600 dark-text h1">
              Start Farming Now!
              </h4>
            
              <a href="#" className="btn btn-lg text-white rajdhani-600 rounded-6 bg-gray-900 border-dark dark-text border-2 px-lg-5 font-sm text-uppercase">Farming </a>

            </div>
          </div>
        </div>
      </div>
   </div>
    </>
  );
}
